import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import { useEffect, useState } from 'react';

const CompletePopupComponent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), 3000);
    return () => clearTimeout(timer);
  }, []);

  if (!isVisible) return null;

  return (
    <div className="complete-popup-layer" onClick={() => setIsVisible(false)}>
      <div className="complete-content" onClick={(e) => e.stopPropagation()}>
        <button type="button" className="complete-modal-close" onClick={() => setIsVisible(false)}>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L17 17" stroke="white" strokeWidth="2"/>
            <path d="M1 17L17 0.999999" stroke="white" strokeWidth="2"/>
          </svg>
        </button>
        <div className="complete-content-inner">
          <img className="complete-content-bg-pc" src="/static/images/complete-bg-pc.png" alt="" />
          <img className="complete-content-bg-sp" src="/static/images/complete-bg-sp.png" alt="" />
          <p className="complete-content-head-1">ご入会いただき<br className="_sp" />ありがとうございます！</p>
          <p className="complete-content-head-2">ワールドプラスジムでは、<br />ジム利用以外にも様々サービスを提供しています。</p>
          <Swiper
            modules={[Pagination, Autoplay]}
            spaceBetween={20}
            slidesPerView={1}
            pagination={{ clickable: true }}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            className="complete-content-blocks"
          >
            <SwiperSlide>
              <div className="complete-content-block">
                <img src="/static/images/complete-banner-1.jpg" alt="" />
                <p>アプリでトレーニング管理</p>
                <p>トレーニングアプリ</p>
                <a href="https://training-app.worldplus-gym.com/" target="_blank" rel="noreferrer">詳しくはこちら</a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="complete-content-block">
                <img src="/static/images/complete-banner-2.jpg" alt="" />
                <p>パーソナルトレーニング</p>
                <p>Fitree（フィットリー）</p>
                <a href="https://personal.fitree.co.jp/" target="_blank" rel="noreferrer">詳しくはこちら</a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="complete-content-block">
                <img src="/static/images/complete-banner-3.jpg" alt="" />
                <p>定期宅配弁当</p>
                <p>Meals（ミールズ）</p>
                <a href="https://store.delishkitchen.tv/lp?u=meals-p-ppp-worldplus-top-cpcd" target="_blank" rel="noreferrer">詳しくはこちら</a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="complete-content-block">
                <img src="/static/images/complete-banner-4.jpg" alt="" />
                <p>ECサイト</p>
                <p>ワールドプラスオンライン</p>
                <a href="https://www.fitree-shop.com/" target="_blank" rel="noreferrer">詳しくはこちら</a>
              </div>
            </SwiperSlide>
          </Swiper>
          <div className="complete-content-bottom">
            <a href="https://lin.ee/dnDV35X" target="_blank" rel="noreferrer">
              <img src="/static/images/complete-banner-5.jpg" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompletePopupComponent;
