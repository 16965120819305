const charDict = {
  zenkaku: [
    "ア",
    "イ",
    "ウ",
    "エ",
    "オ",
    "カ",
    "キ",
    "ク",
    "ケ",
    "コ",
    "サ",
    "シ",
    "ス",
    "セ",
    "ソ",
    "タ",
    "チ",
    "ツ",
    "テ",
    "ト",
    "ナ",
    "ニ",
    "ヌ",
    "ネ",
    "ノ",
    "ハ",
    "ヒ",
    "フ",
    "ヘ",
    "ホ",
    "マ",
    "ミ",
    "ム",
    "メ",
    "モ",
    "ヤ",
    "ユ",
    "ヨ",
    "ラ",
    "リ",
    "ル",
    "レ",
    "ロ",
    "ワ",
    "ヲ",
    "ン",
    "ァ",
    "ィ",
    "ゥ",
    "ェ",
    "ォ",
    "ッ",
    "ャ",
    "ュ",
    "ョ",
    "ー",
    "ガ",
    "ギ",
    "グ",
    "ゲ",
    "ゴ",
    "ザ",
    "ジ",
    "ズ",
    "ゼ",
    "ゾ",
    "ダ",
    "ヂ",
    "ヅ",
    "デ",
    "ド",
    "バ",
    "ビ",
    "ブ",
    "ベ",
    "ボ",
    "パ",
    "ピ",
    "プ",
    "ペ",
    "ポ",
    "ヴ",
    "ヷ",
    "ヺ",
    "　",
    "（",
    "）"
  ],
  hankaku: [
    "ｱ",
    "ｲ",
    "ｳ",
    "ｴ",
    "ｵ",
    "ｶ",
    "ｷ",
    "ｸ",
    "ｹ",
    "ｺ",
    "ｻ",
    "ｼ",
    "ｽ",
    "ｾ",
    "ｿ",
    "ﾀ",
    "ﾁ",
    "ﾂ",
    "ﾃ",
    "ﾄ",
    "ﾅ",
    "ﾆ",
    "ﾇ",
    "ﾈ",
    "ﾉ",
    "ﾊ",
    "ﾋ",
    "ﾌ",
    "ﾍ",
    "ﾎ",
    "ﾏ",
    "ﾐ",
    "ﾑ",
    "ﾒ",
    "ﾓ",
    "ﾔ",
    "ﾕ",
    "ﾖ",
    "ﾗ",
    "ﾘ",
    "ﾙ",
    "ﾚ",
    "ﾛ",
    "ﾜ",
    "ｦ",
    "ﾝ",
    "ｧ",
    "ｨ",
    "ｩ",
    "ｪ",
    "ｫ",
    "ｯ",
    "ｬ",
    "ｭ",
    "ｮ",
    "ｰ",
    "ｶﾞ",
    "ｷﾞ",
    "ｸﾞ",
    "ｹﾞ",
    "ｺﾞ",
    "ｻﾞ",
    "ｼﾞ",
    "ｽﾞ",
    "ｾﾞ",
    "ｿﾞ",
    "ﾀﾞ",
    "ﾁﾞ",
    "ﾂﾞ",
    "ﾃﾞ",
    "ﾄﾞ",
    "ﾊﾞ",
    "ﾋﾞ",
    "ﾌﾞ",
    "ﾍﾞ",
    "ﾎﾞ",
    "ﾊﾟ",
    "ﾋﾟ",
    "ﾌﾟ",
    "ﾍﾟ",
    "ﾎﾟ",
    "ｳﾞ",
    "ﾜﾞ",
    "ｦﾞ",
    " ",
    "(",
    ")"
  ],
};

const regExp = new RegExp(`(${charDict.zenkaku.join("|")})`, "g");

export function zenkakuToHankaku(value) {
  const result = value.replace(regExp, (match) => {
    const index = charDict.zenkaku.indexOf(match);
    return charDict.hankaku[index];
  });
  return result;
}
