import React, { Fragment, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { inputCorpState } from "../states/atoms/inputAtom";
import {
  ROUTES,
  STORAGE_KEY,
  PREFECTURES,
  PAYMENTS,
  PATTERN,
  NUMBER_OF_USERS,
} from "../Constants";
import { useAllShop, useCorpPlanOptions } from "../Repositories";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "../utils/StorageUtil";
import LoadingComponent from "./loading";
import { useMemo } from "react";
import flatpickr from "flatpickr";
import { Japanese } from "flatpickr/dist/l10n/ja.js";
import "flatpickr/dist/flatpickr.min.css";

const InputCorpPlan = () => {
  const [minDate, setMinDate] = useState("today");
  const [maxDate, setMaxDate] = useState(new Date());
  const [defaultDate, setDefaultDate] = useState(null);
  const [input, setInput] = useRecoilState(inputCorpState);
  const [searchParams] = useSearchParams();
  const sessionAccountId = getSessionStorageItem(STORAGE_KEY.CORP_ACCOUNT_ID);
  const accountId =
    searchParams.get(STORAGE_KEY.CORP_ACCOUNT_ID) || sessionAccountId;
  if (accountId !== sessionAccountId) {
    setSessionStorageItem(STORAGE_KEY.CORP_ACCOUNT_ID, accountId);
  }
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const query_account_id = urlParams.get("account_id");

  const { shops, isShopsLoading, isShopsError } = useAllShop(query_account_id ? query_account_id: "");
  if (query_account_id && shops && shops.length === 1) {
    setSessionStorageItem('queryAccountIdForCorp', query_account_id);
  } else {
    setSessionStorageItem('queryAccountIdForCorp', '');
  }
  const { planOptions, isPlansLoading, isPlansError } = useCorpPlanOptions(accountId);

  const setShopName = (accountId, name) => {
    setSessionStorageItem(STORAGE_KEY.CORP_ACCOUNT_ID, accountId);
    setSessionStorageItem(STORAGE_KEY.CORP_SHOP_NAME, name);
    document.title = `ワールドプラスジム　${
      !!name ? name + "　" : ""
    }入会のお申し込み｜ワールドプラスジム`;
  };

  const defaultValues = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const account_id = urlParams.get("accountid");
    const shop = shops?.find((shop) => {
      return (shop.accountid_c === Number(accountId) && !account_id) || (account_id && shop.accountid_c === Number(account_id));
    });
    const prefecture = !!shop?.address_c?.match(PATTERN.PREFECTURE)
      ? shop?.address_c?.match(PATTERN.PREFECTURE)[0]
      : "";
    !!shop && setShopName(shop?.accountid_c, shop?.name);
    return {
      shop: shop,
      shopPrefecture: !!prefecture ? prefecture : input.shopPrefecture,
      shopId: !!shop?.sfid ? shop?.sfid : input.shopId,
      shopName: !!shop?.name ? shop?.name : input.shopName,
      numberOfUsers: input.numberOfUsers,
      admissionYear: input.admissionYear,
      admissionMonth: input.admissionMonth,
      admissionDay: input.admissionDay,
      paymentMethod: input.paymentMethod,
      plan: input.plan,
      options: input.options,
      autoOptions: input.autoOptions,
    };
  }, [shops, accountId, input]);

  const {
    register,
    handleSubmit,
    watch,
    trigger,
    setValue,
    getValues,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "all",
    defaultValues: defaultValues,
  });

  const numberOfUsers = watch('numberOfUsers');

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  useEffect(() => {
    let today = new Date();
    const shop = defaultValues.shop || {};
    if (input.admissionYear && input.admissionMonth && input.admissionDay) {
      today = new Date(input.admissionYear, input.admissionMonth-1, input.admissionDay);
    }
    if (shop.membershipstartdate_c) {
      const startDate = new Date(shop.membershipstartdate_c);
      if (startDate > today) {
        setMinDate(startDate);
        setDefaultDate(startDate);
        const futureMaxDate = new Date(startDate);
        futureMaxDate.setDate(startDate.getDate() + 90);
        setMaxDate(futureMaxDate);
      } else {
        setMinDate("today");
        setDefaultDate(today);
        const todayMaxDate = new Date();
        todayMaxDate.setDate(today.getDate() + 90);
        setMaxDate(todayMaxDate);
      }
    } else {
      setMinDate("today");
      setDefaultDate(today);
      const defaultMaxDate = new Date();
      defaultMaxDate.setDate(defaultMaxDate.getDate() + 90);
      setMaxDate(defaultMaxDate);
    }
  }, [defaultValues.shop, input.admissionYear, input.admissionMonth, input.admissionDay]);

  const inputRef = useRef();
  const fpRef = useRef(null);

  useEffect(() => {
    if (!inputRef.current) return;
    if (fpRef.current) {
      fpRef.current?.destroy();
    }

    const handleDateSelect = (selectedDates) => {
      const selectedDate = selectedDates[0];
      if (selectedDate) {
        const jstDate = new Date(selectedDate);
        jstDate.setHours(jstDate.getHours() + 9);
        const formattedDate = jstDate.toISOString().split("T")[0];
        let [year, month, day] = formattedDate.split("-");
        month = parseInt(month, 10).toString();
        day = parseInt(day, 10).toString();
        setValue("admissionYear", year);
        setValue("admissionMonth", month);
        setValue("admissionDay", day);
        setInput((currentInput) => ({
          ...currentInput,
          admissionYear: year,
          admissionMonth: month,
          admissionDay: day,
        }));
        trigger("admissionYear");
        trigger("admissionMonth");
        trigger("admissionDay");
      }
    };

    fpRef.current = flatpickr(inputRef.current, {
      locale: Japanese,
      dateFormat: "Y年n月j日",
      minDate: minDate,
      maxDate: maxDate,
      defaultDate: defaultDate,
      disableMobile: true,
      onChange: handleDateSelect,
      onReady: (selectedDates, dateStr, instance) => {
        handleDateSelect([instance.selectedDates[0]]);
      },
    });
    return () => {
      fpRef.current?.destroy();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef.current, setValue, trigger, minDate, maxDate, defaultDate]);

  if (isShopsError || isPlansError) return <LoadingComponent />;
  if (isShopsLoading) return <LoadingComponent />;

  const shop = !!accountId
    ? shops.find((shop) => {
        return shop.accountid_c === Number(accountId);
      })
    : null;
  const shopName = !shop ? "" : shop.name;
  !!shopName && setSessionStorageItem(STORAGE_KEY.CORP_SHOP_NAME, shopName);

  const onSubmit = (data) => {
    const updateInput = {
      shopPrefecture: data.shopPrefecture,
      shopId: data.shopId,
      numberOfUsers: data.numberOfUsers,
      admissionYear: data.admissionYear,
      admissionMonth: data.admissionMonth,
      admissionDay: data.admissionDay,
      paymentMethod: data.paymentMethod,
      plan: data.plan,
      options: data.options,
      autoOptions: data.autoOptions,
    };
    setInput((currentInput) => ({
      ...currentInput,
      ...updateInput,
    }));
    navigate(ROUTES.corpProfile);
  };

  // Formバリデーションルール
  const VALIDATION_RULES = {
    shopPrefecture: {
      required: "都道府県を選択してください",
    },
    shopId: {
      required: "店舗を選択してください",
    },
    numberOfUsers: {
      required: "ご利用人数を選択してください",
    },
    admissionYear: {
      required: "ご入会日(年)を選択してください",
    },
    admissionMonth: {
      required: "ご入会日(月)を選択してください",
    },
    admissionDay: {
      required: "ご入会日(日)を選択してください",
      validate: {
        beforeToday: (value) => {
          if (!!getValues("admissionYear") && !!getValues("admissionMonth")) {
            const msg_today = "本日以降の日付を選択してください";
            const msg_90_day = "本日より90日以内の日付を選択してください";

            // 本日日付の取得
            const today = new Date();
            const currentYear = today.getFullYear();
            const currentMonth = today.getMonth() + 1;
            const currentDay = today.getDate();

            const ninetyDaysLater = new Date(today);
            ninetyDaysLater.setDate(today.getDate() + 90);

            // 入力された日付の取得
            const inputYear = Number(getValues("admissionYear"));
            const inputMonth = Number(getValues("admissionMonth"));
            const inputDay = Number(value);
            const inputDate = new Date(inputYear, inputMonth - 1, inputDay);

            if (inputDate > ninetyDaysLater) {
              return msg_90_day;
            } else if (currentYear === inputYear) {
              if (currentMonth === inputMonth) {
                return currentDay <= Number(value) || msg_today;
              } else {
                return currentMonth < inputMonth || msg_today;
              }
            }　else if (inputYear < currentYear) {
              return msg_today;
            }
          }
          return null;
        },
        invalid: (value) => {
          if (!!getValues("admissionYear") && !!getValues("admissionMonth")) {
            // 入力された日付の取得
            const inputYear = getValues("admissionYear");
            const inputMonth = getValues("admissionMonth");
            const date = new Date(inputYear + "/" + inputMonth + "/" + value);
            if (
              Number(inputYear) !== date.getFullYear() ||
              Number(inputMonth) !== date.getMonth() + 1 ||
              Number(value) !== date.getDate()
            ) {
              return "正しい日付を選択してください";
            }

            // 開業日以降の日付を選択判定
            if (shop?.membershipstartdate_c) {
              const m = new Date(shop.membershipstartdate_c.replace(/-/g,"/"));
              const mY = m.getFullYear();
              const mM = m.getMonth() + 1
              const mD = m.getDate();
              const memberShipStartDate = Number(shop.membershipstartdate_c.replace(/-/g,""));
              const choiceDate = Number(`${getValues("admissionYear")}${getValues("admissionMonth").padStart(2, "0")}${value.padStart(2, "0")}`);
              if (memberShipStartDate > choiceDate) {
                return `店舗オープン日（${mY}年${mM}月${mD}日）以降を選択してください`;
              }
            }
          }
          return null;
        },
      },
    },
    paymentMethod: {
      required: "決済方法を選択してください",
    },
    plan: {
      required: "プランを選択してください",
    },
    options: {
      // バリデーションなし
    },
    autoOptions: {},
  };

  const selectedPlan = planOptions?.plans.filter((plan) => {
    return watch("plan") === plan.sfid;
  })[0];

  const isPlanAnnualOrImmediate = selectedPlan?.annualcontractplan_c || selectedPlan?.immediatesettlementadaptationflag_c;

  if (planOptions && planOptions.options.find((option) => option.optionautomaticallygrantedenrollment_c)) {
    VALIDATION_RULES['autoOptions'] = {
      required: "自動付与オプションを選択してください",
    }
  }

  const useSelectPref = (event) => {
    reset({ shopId: "" });
    event.target.blur();
    setNumberOfUsers();
    trigger(event.target.name);
  };

  const getPrice = (number) => {
    if (number >= 5 && number <= 20) {
      return 5470;
    } else if (number >= 21 && number <= 50) {
      return 4370;
    } else if (number > 50) {
      return 3270;
    } else {
      return 0;
    }
  }

  const setNumberOfUsers = () => {
    if (planOptions) {
      const numberOfUsers = watch('numberOfUsers');
      const planPrice = getPrice(numberOfUsers);
      const plan = planOptions.plans.find((plan) => plan.initialfee_c === planPrice);
      setValue('plan', plan.sfid);
      setSessionStorageItem(
        STORAGE_KEY.CORP_SELECTED_PLAN,
        plan
      );
    }
  }

  const useSelectNumberOfUsers = (event) => {
    const numberOfUsers = Number(event.target.value);
    const planPrice = getPrice(numberOfUsers);
    if (planOptions) {
      const plan = planOptions.plans.find((plan) => plan.initialfee_c === planPrice);
      setValue('plan', plan.sfid);
      setSessionStorageItem(
        STORAGE_KEY.CORP_SELECTED_PLAN,
        plan
      );
    }
    event.target.blur();
  }

  /**
   *
   *
   * @param {*} event
   */
  const toggleShopRadio = (event, value) => {
    fpRef.current = null;
    setInput((currentInput) => ({
      ...currentInput,
      shopName: value.name,
      shopType: value.shoptype_c,
      options: [],
    }));
    setShopName(value.accountid_c, value.name);
    setNumberOfUsers();
    trigger(event.target.name);

    const today = new Date();

    if (value.membershipstartdate_c) {
      const startDate = new Date(value.membershipstartdate_c);
      if (startDate > today) {
        setMinDate(startDate);
        setDefaultDate(startDate); // defaultDate もオープン日に変更
        const futureMaxDate = new Date(startDate);
        futureMaxDate.setDate(startDate.getDate() + 90);
        setMaxDate(futureMaxDate);
      } else {
        setMinDate("today");
        setDefaultDate(today); // 過去の店舗は今日を defaultDate に
        const todayMaxDate = new Date();
        todayMaxDate.setDate(today.getDate() + 90);
        setMaxDate(todayMaxDate);
      }
    } else {
      setMinDate("today");
      setDefaultDate(today);
      const defaultMaxDate = new Date();
      defaultMaxDate.setDate(defaultMaxDate.getDate() + 90);
      setMaxDate(defaultMaxDate);
    }
  };

  /**
   *
   *
   * @returns
   */
  // const getAdmissionYearList = () => {
  //   let currentYear = new Date().getFullYear();
  //   const yearList = [""]; // 初期値の空文字を設定
  //   for (var i = 0; i <= 2; i++) {
  //     yearList.push(currentYear + i);
  //   }
  //   return yearList;
  // };

  return (
    <main className="main">
      {/* cover */}
      <div className="cover cover--admission cover--bg">
        <div className="cover__outer">
          <div className="cover__inner"></div>
        </div>
      </div>
      {/* /cover */}

      {/* location */}
      <div className="location">
        <p className="location__text">
          <a href={process.env.REACT_APP_WEB_MAIN_URL}>TOP</a>
          <a href={process.env.REACT_APP_WEB_MAIN_URL + "/corp/admission/"}>
            ワールドプラスジム　{!!shopName ? shopName + "　" : ""}
            法人入会のお申し込み
          </a>
        </p>
      </div>
      {/* /location */}

      {/* content */}
      <div className="content">
        <div className="content__outer">
          <div className="content__inner">
            <h1 className="content__title js-indicate js-indicate-btin">
              ワールドプラスジム　
              {!!shopName ? shopName + "　" : ""}
              法人入会のお申し込み
            </h1>

            {/* step */}
            <div className="step step--small js-indicate js-indicate-btin">
              <div className="step__lists">
                <div className="step__list step__list--current">
                  <span>
                    店舗・<i className="pc">利用</i>人数<i className="pc">の入力</i>
                  </span>
                </div>
                <div className="step__list">
                  <span>
                    法人情報<i className="pc">の入力</i>
                  </span>
                </div>
                <div className="step__list">
                  <span>
                    決済情報<i className="pc">の入力</i>
                  </span>
                </div>
                <div className="step__list">
                  <span>
                    <i className="pc">登録</i>完了
                  </span>
                </div>
              </div>
            </div>
            {/* /step */}

            <p className="content__text js-indicate js-indicate-btin">
              クレジットカード／口座情報と身分証明書をお手元にご準備いただき、 必要事項をご記入のうえ、「会員情報の入力へ」ボタンを押してください。
              <br />
              <span className="need">必須</span>は必須項目です。
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="content__h2 content__h2--line js-indicate js-indicate-btin"></div>

              {/* formbox */}
              <div className="formbox js-indicate js-indicate-btin">
                <div className="formbox__item">
                  {!!input.errorMsg && (
                    <p
                      style={{
                        textAlign: "center",
                        margin: "0 30px",
                        padding: "30px 0 25px",
                      }}
                    >
                      <span
                        className="aform-error"
                        style={{ display: "block" }}
                      >
                        {input.errorMsg}
                      </span>
                      <span
                        id="fsjs_error"
                        className="aform-error"
                        style={{ display: "block" }}
                      ></span>
                    </p>
                  )}
                  <dl className="dl_shop">
                    <dt>
                      <label htmlFor="shop" className="aform-label shop">
                        店舗<span className="aform-required">必須</span>
                      </label>
                    </dt>
                    <dd>
                      <select
                        {...register(
                          "shopPrefecture",
                          VALIDATION_RULES.shopPrefecture
                        )}
                        id="shop"
                        className={
                          errors.shopPrefecture
                            ? "form1_pref__select parsley-error"
                            : "form1_pref__select valid"
                        }
                        title="都道府県を選択してください"
                        onChange={useSelectPref}
                      >
                        {!query_account_id && shops.length === 1 && (
                          <option value="">都道府県を選択してください</option>
                        )}
                        {query_account_id && shops.length === 1 && getValues('shopPrefecture') ? PREFECTURES.filter((p) => p.label === getValues('shopPrefecture')).map((value) => (
                          <option value={value.label} key={value.id}>
                            {value.label}
                          </option>
                        )): PREFECTURES.map((value) => (
                          <option value={value.label} key={value.id}>
                            {value.label}
                          </option>
                        ))}
                      </select>
                      <div
                        className={
                          errors.shopId
                            ? "form2_shops parsley-error"
                            : "form2_shops"
                        }
                        style={{
                          display: !!watch("shopPrefecture") ? "block" : "none",
                        }}
                      >
                        {shops.map(
                          (value) =>
                            value.address_c?.includes(watch("shopPrefecture")) >
                              0 && (
                              <div
                                className="form2_shops__item"
                                key={value.sfid}
                              >
                                <input
                                  {...register(
                                    "shopId",
                                    VALIDATION_RULES.shopId
                                  )}
                                  id={value.sfid}
                                  type="radio"
                                  className="form2_shops__radio aform-input aform-radio"
                                  value={value.sfid}
                                  onClick={(event) => {
                                    toggleShopRadio(event, value);
                                  }}
                                />
                                <label
                                  htmlFor={value.sfid}
                                  className="form2_shops__item-info"
                                >
                                  <div className="form2_shops__item-info__name">
                                    {value.name}
                                  </div>
                                  <div className="form2_shops__item-info__addr">
                                    {value.address_c}
                                  </div>
                                  <div className="form2_shops__item-info__tel">
                                    {value.phone_c}
                                  </div>
                                </label>
                                {value.storewebsiteurl_c ? (
                                  <a
                                    href={value.storewebsiteurl_c}
                                    className="form2_shops__link"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    店舗詳細ページ
                                  </a>
                                ) : (
                                  <></>
                                )}
                              </div>
                            )
                        )}
                      </div>
                      <div className="aform-error">
                        {(errors.shopPrefecture || errors.shopId) && (
                          <ul className="parsley-errors-list filled">
                            {errors.shopPrefecture && (
                              <li className="parsley-required">
                                {errors.shopPrefecture.message}
                              </li>
                            )}
                            {errors.shopId && (
                              <li className="parsley-required">
                                {errors.shopId.message}
                              </li>
                            )}
                          </ul>
                        )}
                      </div>
                    </dd>
                  </dl>
                </div>

                <div className="formbox__item">
                  <dl>
                    <dt>
                      <label>
                        ご入会日<span className="aform-required">必須</span>
                      </label>
                    </dt>
                    <dd className="formbox__date">
                      <input
                        type="text"
                        ref={inputRef}
                      />
                      <div className="aform-error">
                        {(errors.admissionYear ||
                          errors.admissionMonth ||
                          errors.admissionDay) && (
                          <ul className="parsley-errors-list filled">
                            {errors.admissionYear && (
                              <li className="parsley-required">
                                {errors.admissionYear.message}
                              </li>
                            )}
                            {errors.admissionMonth && (
                              <li className="parsley-required">
                                {errors.admissionMonth.message}
                              </li>
                            )}
                            {errors.admissionDay && (
                              <li className="parsley-required">
                                {errors.admissionDay.message}
                              </li>
                            )}
                          </ul>
                        )}
                      </div>
                      <div className="formbox__note">
                        <span className="form-description-date">
                          ※施設のご利用や指紋等の登録は、オープン日から可能です。
                          <br />
                        </span>
                        初月会費は、日割り計算になります。
                        <br />
                        (例)1日入会→6570円　10日入会→4161円
                      </div>
                    </dd>
                  </dl>
                </div>
                <div className="formbox__item">
                  <dl>
                    <dt>
                      <label htmlFor="numberOfUsers" className="aform-label">
                        ご利用人数<span className="aform-required">必須</span>
                      </label>
                    </dt>
                    <dd>
                      <select
                        {...register(
                          "numberOfUsers",
                          VALIDATION_RULES.numberOfUsers
                        )}
                        id="numberOfUsers"
                        className={
                          errors.numberOfUsers
                            ? "form1_pref__select parsley-error"
                            : "form1_pref__select valid"
                        }
                        title="ご利用人数を選択してください"
                        onChange={useSelectNumberOfUsers}
                      >
                        <option value="">ご利用人数を選択してください</option>
                        {NUMBER_OF_USERS.map((value) => (
                          <option value={value.label} key={value.id}>
                            {value.label}
                          </option>
                        ))}
                      </select>
                      <div className="aform-error">
                        {errors.numberOfUsers && (
                          <ul className="parsley-errors-list filled">
                            {errors.numberOfUsers && (
                              <li className="parsley-required">
                                {errors.numberOfUsers.message}
                              </li>
                            )}
                          </ul>
                        )}
                      </div>
                      <div className="formbox__note">
                        ご利用人数に応じて会費が異なります。<br/>
                        契約人数：5人～20人4,980円（税込5,470円）／1人<br/>
                        契約人数：21人～50人 3,980円（税込4,370円）／1人<br/>
                        契約人数：51人以上 2,980円（税込3,270円）／1人
                      </div>
                    </dd>
                  </dl>
                </div>

              </div>
              {/* /formbox */}

              <div className="content__h2 content__h2--line js-indicate js-indicate-btin"></div>

              {/* formbox */}
              {!!planOptions && (
                <div className="formbox js-indicate js-indicate-btin">
                  <div className="formbox__item formbox__card">
                    <dl>
                      <dt>
                        <span>
                          決済方法
                          <span className="aform-required">必須</span>
                        </span>
                      </dt>
                      <dd>
                        <ul className="aform-radio-ul form-payment-type-radio">
                          <li
                            style={{
                              display:
                                !!planOptions &&
                                planOptions.plans.filter((plan) => {
                                  return plan.card_c;
                                }).length > 0
                                  ? "block"
                                  : "none",
                            }}
                          >
                            {process.env.REACT_APP_CREDIT_CARD_NONSELECTABLE_MODE === 'true' ? (
                              <input
                                {...register(
                                  "paymentMethod",
                                  VALIDATION_RULES.paymentMethod
                                )}
                                disabled
                                id="payment01"
                                type="radio"
                                className="jsc-credit"
                                value={PAYMENTS.CREDIT_CARD}
                              ></input>
                          ): (
                              <input
                                {...register(
                                  "paymentMethod",
                                  VALIDATION_RULES.paymentMethod
                                )}
                                id="payment01"
                                type="radio"
                                className="jsc-credit"
                                value={PAYMENTS.CREDIT_CARD}
                              ></input>
                            )}
                            <label htmlFor="payment01">法人クレジットカード</label>
                          </li>
                          <li
                            style={{
                              display:
                                !!planOptions &&
                                planOptions.plans.filter((plan) => {
                                  return plan.transfer_c;
                                }).length > 0
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <input
                              {...register(
                                "paymentMethod",
                                VALIDATION_RULES.paymentMethod
                              )}
                              id="payment02"
                              type="radio"
                              className="jsc-transfer"
                              value={PAYMENTS.BANK_TRANSFER}
                            ></input>
                            <label htmlFor="payment02">法人口座振替<br className="sp"/>（法人口座もしくは代表者様口座）</label>
                          </li>
                        </ul>
                        {process.env.REACT_APP_CREDIT_CARD_NONSELECTABLE_MODE === 'true' && (
                          <div class="formbox__note">※システムメンテナンス中（クレジット）</div>
                        )}
                        {process.env.REACT_APP_CREDIT_CARD_MESSAGE && (
                          <div class="formbox__note aform-error">{process.env.REACT_APP_CREDIT_CARD_MESSAGE}</div>
                        )}
                        <div id="card-error" className="aform-error"></div>
                      </dd>
                    </dl>
                  </div>
                  {planOptions.options.length !== 0 && (
                    <div
                      className="formbox__item "
                      style={{
                        display: watch("paymentMethod") === "" ? "none" : "block",
                      }}
                    >
                      <dl>
                        <dt>
                          <label>オプション</label>
                        </dt>
                        <dd>
                          {planOptions.options.filter((option) => !option.optionautomaticallygrantedenrollment_c).map((option, i) => (
                            <div key={i}>
                              <label className="optionLabel" htmlFor={`options.${planOptions.options.indexOf(option)}`}>
                                <input
                                  id={`options.${planOptions.options.indexOf(option)}`}
                                  type="checkbox"
                                  placeholder="check"
                                  value={option.sfid}
                                  {...register(
                                    `options.${planOptions.options.indexOf(option)}`,
                                    VALIDATION_RULES.options
                                  )}
                                />
                                {`${option.name}(${Number(option.amount_c).toLocaleString()}円)`}
                                {numberOfUsers && ` × ${numberOfUsers}人分`}
                              </label>
                              {option.description &&
                                <p className="formbox__note">
                                  {option.description
                                    .split(/\n|<br>|<br\/>|<br \/>/)
                                    .map((str, ii) => {
                                      return (
                                        <Fragment key={ii}>
                                          {str}
                                          <br />
                                        </Fragment>
                                      );
                                    })
                                  }</p>}
                              <br />
                              <br className="pc" />
                            </div>
                          ))}
                          {selectedPlan && planOptions.options.find((option) => option.optionautomaticallygrantedenrollment_c && !(isPlanAnnualOrImmediate && option.hiddenannualcontract_c)) ? (
                            <table className="form-auto-option">
                              <thead>
                                <tr>
                                  <th colSpan="2">
                                    自動で追加されるオプション
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {planOptions.options.filter((option) => option.optionautomaticallygrantedenrollment_c && !(isPlanAnnualOrImmediate && option.hiddenannualcontract_c)).map((option, i) => {
                                  return (
                                    <tr>
                                      <th>
                                        {`${option.name}(${Number(
                                          option.amount_c
                                        ).toLocaleString()}円)`}
                                        <input
                                          type="checkbox"
                                          placeholder="check"
                                          defaultChecked={true}
                                          value={option.sfid}
                                          {...register(
                                            `options.${planOptions.options.indexOf(option)}`,
                                            VALIDATION_RULES.options
                                          )}
                                        />
                                      </th>
                                      <td>
                                      {option.optiontermsconditions_c &&
                                        <p className="formbox__note">
                                          {option.optiontermsconditions_c
                                            .split(/\n|<br>|<br\/>|<br \/>/)
                                            .map((str, ii) => {
                                              return (
                                                <Fragment key={ii}>
                                                  {str}
                                                  <br />
                                                </Fragment>
                                              );
                                            })
                                          }</p>}
                                      </td>
                                    </tr>
                                  )
                                })}
                                <tr>
                                  <td colSpan="2" className="autoOptionTd">
                                    <div>
                                      <span class="aform-required">必須</span>
                                      <label htmlFor="autoOptions">
                                        <input
                                          id="autoOptions"
                                          type="checkbox"
                                          placeholder="check"
                                          value="true"
                                          {...register(
                                            "autoOptions",
                                            VALIDATION_RULES.autoOptions
                                          )}
                                        />
                                        自動更新及び解約について確認しました
                                      </label>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          ) : (
                            planOptions.options.filter((option) => option.optionautomaticallygrantedenrollment_c).map((option, i) => {
                              return (
                                <input
                                  style={{ display: 'none' }}
                                  type="checkbox"
                                  value={option.sfid}
                                  {...register(`options.${planOptions.options.indexOf(option)}`, {
                                    ...VALIDATION_RULES.options,
                                    setValueAs: () => false,
                                  })}
                                />
                              );
                            })
                          )}
                        </dd>
                      </dl>
                    </div>
                  )}
                </div>
              )}
              {/* /formbox */}

              {/* message */}
              <div className="js-indicate js-indicate-btin">
                <p className="message__guide">
                  ご不明な点は{" "}
                  <a href={process.env.REACT_APP_WEB_MAIN_URL + "/contact/"}>
                    こちら
                  </a>{" "}
                  にお問い合わせください。
                </p>
              </div>
              {/* /message */}

              {isPlansLoading && (
                <div className="loading-plan">
                  <div className="loading__progress">
                    <div className="loading__icon"></div>
                  </div>
                </div>
              )}

              {/* buttons */}
              <div className="buttons js-indicate js-indicate-btin">
                <button
                  type="submit"
                  className="button button--large button--arrow js-submit"
                  disabled={!isValid || isPlansLoading}
                >
                  <span className="button__label">法人情報の入力へ</span>
                </button>
              </div>
              {/* /buttons */}

              {/* formbox */}
              {!!planOptions &&
                planOptions.plans
                  .filter((plan) => {
                    return watch("plan") === plan.sfid;
                  })
                  .map((filteredPlan) => (
                    <div
                      key={filteredPlan.sfid}
                      className="formbox js-indicate js-indicate-btin"
                    >
                      <div className="formbox__item formbox__price formbox__price--first">
                        <dl>
                          <dt>
                            <label>各種料金</label>
                          </dt>
                          <dd>
                            <table>
                              <thead>
                                <tr>
                                  <th colSpan="2" className="campaign_name">
                                    {filteredPlan.name}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>入会金</th>
                                  <td className="admission_fee">
                                    ¥
                                    {Number(
                                      filteredPlan.admissionfee_c
                                    ).toLocaleString()}
                                  </td>
                                </tr>
                                <tr>
                                  <th>事務手数料</th>
                                  <td className="commission">
                                    ¥
                                    {Number(
                                      filteredPlan.administrativefee_c
                                    ).toLocaleString()}
                                  </td>
                                </tr>
                                {filteredPlan.annualcontractplan_c ? (
                                  <tr>
                                    <th>年会費</th>
                                    <td className="monthly_fee">
                                      ¥
                                      {Number(
                                        filteredPlan.initialfee_c
                                      ).toLocaleString()}
                                    </td>
                                  </tr>
                                ): (
                                  <tr>
                                    <th>月会費</th>
                                    <td className="monthly_fee">
                                      ¥
                                      {Number(
                                        filteredPlan.monthlyfee_c * watch("numberOfUsers")
                                      ).toLocaleString()}
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                            <div className="formbox__note">
                              ＊すべて税込価格になります。
                              <br />
                              ＊月途中での入会の場合、月末までの残日数×月会費（1日あたり）の計算結果が初月分のお支払い金額となります。
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  ))}

              {/* /formbox */}
            </form>
            {/* <% end %> */}
          </div>
        </div>
      </div>
      {/* /content */}
    </main>
    // /main
  );
};

export default InputCorpPlan;
