import axios from "axios";
import React, { useState } from "react";
import moment from "moment";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { inputCorpState } from "../states/atoms/inputAtom";
import { smbcTransferFormSubmit, gmoFinCodeGetToken } from "../utils/PaymentUtils";
import { getSessionStorageItem } from "../utils/StorageUtil";
import { zenkakuToHankaku } from "../utils/HankakuKana";
import {
  ROUTES,
  PAYMENTS,
  STORAGE_KEY,
  API_ENDPOINT,
  JAPANESE_DATE_FORMAT,
  PATTERN,
} from "../Constants";
import { useCorpPaymentInfo, useCsrf } from "../Repositories";
import LoadingComponent from "./loading";

const InputCorpPayment = () => {
  const [input, setInput] = useRecoilState(inputCorpState);
  const [isEmailCheckModal, setEmailCheckModal] = useState(false);
  const shopName = getSessionStorageItem(STORAGE_KEY.CORP_SHOP_NAME);
  const navigate = useNavigate();
  const { token, isCsrfLoading, isCsrfError } = useCsrf();
  const { paymentInfo, isPaymentInfoLoading, isPaymentInfoError } =
    useCorpPaymentInfo(
      input.plan,
      input.shopId,
      input.paymentMethod,
      input.options ? input.options?.filter(Boolean): '',
      input.admissionYear,
      input.admissionMonth,
      input.admissionDay,
      input.numberOfUsers,
    );

  const selectedPlan = getSessionStorageItem(STORAGE_KEY.CORP_SELECTED_PLAN);

  // Form
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: "all",
    defaultValues: {
      cardNo: input.cardNo,
      cardExpirationYear: input.cardExpirationYear,
      cardExpirationMonth: input.cardExpirationMonth,
      cvv: input.cvv,
      holderName: input.holderName,
      smbcBillKana: input.smbcBillKana,
    },
  });

  if (isCsrfLoading || isCsrfError) return <LoadingComponent />;
  if (isPaymentInfoLoading || isPaymentInfoError) return <LoadingComponent />;

  const onSubmit = async (data) => {
    const submitElement = document.querySelector('[type="submit"]');
    submitElement?.setAttribute('disabled', 'true');
    const loadingElement = document.querySelector('.loading2');
    loadingElement?.classList.add("show");
    const headers = {
      "X-CSRFToken": token.token,
    };
    const formData = new FormData();
    formData.append("email", input.email);
    formData.append("brand_type", "ワールドプラスジム");
    const result = await fetch("/api/sf_email_check", {
      method: "POST",
      headers: headers,
      body: formData,
    });
    const resultData = await result.json();
    if (resultData.status === 'NG') {
      submitElement?.removeAttribute('disabled');
      loadingElement?.classList.remove("show");
      window.scrollTo(0, 0);
      setEmailCheckModal(true);
      return;
    }
    loadingElement?.classList.remove("show");
    navigate(ROUTES.loading, { replace: true });
    if (input.paymentMethod === PAYMENTS.CREDIT_CARD) {
      gmoFinCodeGetToken(
        getValues("cardNo"),
        getValues("cardExpirationYear") +
        getValues("cardExpirationMonth"),
        getValues("cvv"),
        getValues("holderName"),
        gmoFincodeResponse
      );
    } else if (input.paymentMethod === PAYMENTS.BANK_TRANSFER) {
      const updateInput = {
        smbcBillKana: data.smbcBillKana,
      };
      setInput((currentInput) => ({
        ...currentInput,
        ...updateInput,
      }));
      registerMemberInfo((response) => {
        smbcTransferFormSubmit(
          response.data.smbcMemberNo,
          input.lastName + "　" + input.firstName,
          zenkakuToHankaku(data.smbcBillKana),
          response.data.memberId,
          'corp',
        );
      });
    }
  };

  function gmoFincodeResponse(status, response) {
    if (status !== 200) {
      alert(
        "決済処理に失敗しました。クレジットカード情報をご確認のうえ、再度ご入力ください。"
      );
      navigate(ROUTES.payment, { replace: true });
    } else {
      registerMemberInfo((response) => {
        navigate(ROUTES.register_complete, {
          state: response.data,
          replace: true,
        });
      }, response.list[0].token);
    }
  }

  // Formバリデーションルール
  const VALIDATION_RULES = {
    cardNo: {
      required: "クレジットカード番号を入力してください",
      pattern: {
        value: /^\d+$/,
        message:
          "クレジットカード番号は、半角数字・ハイフンなしで入力してください",
      },
    },
    cardExpirationYear: {
      required: "有効期限(年)を入力してください",
    },
    cardExpirationMonth: {
      required: "有効期限(月)を入力してください",
    },
    cvv: {
      required: "セキュリティコードを入力してください",
      pattern: {
        value: /^\d{3,4}$/,
        message:
          "セキュリティコードは、半角数字・ハイフンなし(3桁または4桁)で入力してください",
      },
    },
    holderName: {
      required: "クレジットカード名義を入力してください",
      maxLength: {
        value: 50,
        message: "クレジットカード名義は50文字以内で入力してください",
      },
    },
    smbcBillKana: {
      required: "口座名義人名フリガナを入力してください",
      pattern: {
        value: PATTERN.ZENKAKU_KANA_TRANSFER,
        message: "口座名義人名フリガナは、全角で入力してください",
      },
    },
  };

  /**
   * 有効期限（年）の選択肢を生成する
   *
   * @returns 有効期限（年）の選択肢
   */
  const getExpirationYearList = () => {
    let currentYear = Number(new Date().getFullYear().toString().slice(-2));
    const yearList = [""];
    for (var i = 0; i <= 20; i++) {
      yearList.push(("00" + (currentYear + i)).slice(-2));
    }
    return yearList;
  };

  const registerMemberInfo = (callback, gmoToken) => {
    const headers = {
      "X-CSRFToken": token.token,
      "content-type": "multipart/form-data",
    };
    const data = {
      shopId: input.shopId,
      admissionYear: input.admissionYear,
      admissionMonth: input.admissionMonth,
      admissionDay: input.admissionDay,
      paymentMethod: input.paymentMethod,
      plan: input.plan,
      options: input.options?.filter((option) => option !== false).join(",") || '',
      numberOfUsers: input.numberOfUsers,
      companyName: input.companyName,
      companyNameKana: input.companyNameKana,
      companyPhoneNumber: input.companyPhoneNumber,
      companyZipcode: input.companyZipcode,
      companyPrefecture: input.companyPrefecture,
      companyCity: input.companyCity,
      companyStreet: input.companyStreet,
      lastName: input.lastName,
      firstName: input.firstName,
      lastNameKana: input.lastNameKana,
      firstNameKana: input.firstNameKana,
      birthYear: input.birthYear,
      birthMonth: input.birthMonth,
      birthDay: input.birthDay,
      gender: input.gender,
      phoneNumber: input.phoneNumber,
      email: input.email,
      password: input.password,
      zipcode: input.zipcode,
      prefecture: input.prefecture,
      city: input.city,
      street: input.street,
    };
    if (input.paymentMethod === PAYMENTS.CREDIT_CARD) {
      data["gmoToken"] = gmoToken;
    }
    axios
      .post(
        "/api" + API_ENDPOINT.CORP_REGISTER,
        {
          ...data,
        },
        { headers: headers, withCredentials: true }
      )
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        setInput((currentInput) => ({
          ...currentInput,
          errorMsg: error.response?.data?.msg,
        }));
        if (error.response.status === 500) {
          navigate(ROUTES.corpPlan, { replace: true });
        } else {
          navigate(ROUTES.corpPayment, { replace: true });
        }
      });
  };

  const emailCheckClose = (event) => {
    if (event.target.className === 'email-modal-layer') {
      setEmailCheckModal(false);
    }
  }

  const calculateMembershipPeriod = (startDate) => {
    // 引数で渡された日付をDateオブジェクトに変換
    const start = new Date(startDate.replace(/-/g,"/"));
    // 開始日の日付情報を取得
    const startYear = start.getFullYear();
    const startMonth = start.getMonth() + 1;

    // 11ヶ月後の日付を計算
    const end = new Date(start);
    end.setMonth(end.getMonth() + 11);

    // 終了日の日付情報を取得
    const endYear = end.getFullYear();
    const endMonth = start.getDate() === 1 ? end.getMonth() + 1: end.getMonth() + 2;

    // 文字列フォーマットを作成
    const result = `${startYear}年${startMonth}月〜${endYear}年${endMonth}月の会費`;
    return result;
  }

  return (
    <main className="main">
      {/* cover */}
      <div className="cover cover--contact cover--bg">
        <div className="cover__outer">
          <div className="cover__inner"></div>
        </div>
      </div>
      {/* /cover */}

      {/* location */}
      <div className="location">
        <p className="location__text">
          <a href="{/* ENV['WEB_MAIN_URL'] */}">TOP</a>
          <a href="{/* ENV['WEB_MAIN_URL'] */}/admission/">
            ワールドプラスジム　{!!shopName && shopName + "　"}
            法人入会のお申し込み
          </a>
        </p>
      </div>
      {/* /location */}

      {/* content */}
      <div className="content">
        <div className="content__outer">
          <div className="content__inner">
            <h1 className="content__title js-indicate js-indicate-btin">
              ワールドプラスジム　{!!shopName && shopName + "　"}
              法人入会のお申し込み
            </h1>

            {/* step */}
            <div className="step js-indicate js-indicate-btin">
              <div className="step__lists">
                <div className="step__list">
                  <span>
                    店舗・<i className="pc">利用</i>人数<i className="pc">の入力</i>
                  </span>
                </div>
                <div className="step__list">
                  <span>
                    法人<i className="pc">情報の入力</i>
                  </span>
                </div>
                <div className="step__list step__list--current">
                  <span>
                    決済<i className="pc">情報の入力</i>
                  </span>
                </div>
                <div className="step__list">
                  <span>
                    登録<i className="pc">完了</i>
                  </span>
                </div>
              </div>
            </div>
            {/* /step */}

            <p className="content__text js-indicate js-indicate-btin">
              必要事項をご記入のうえ、「申し込む」ボタンを押してください。
              <br />
              入力内容を訂正する場合は、「戻る」ボタンを押してください。
              <br />
              <span className="need">必須</span>は必須項目です。
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="content__h2 content__h2--line js-indicate js-indicate-btin"></div>
              {/* formbox */}
              <div className="formbox js-indicate js-indicate-btin">
                <div className="formbox__item">
                  <dl className="dl_parts-2">
                    <dt>
                      <span className="aform-label parts-2">決済方法</span>
                    </dt>
                    <dd>
                      <span className="aform-confirm">
                        {input.paymentMethod === PAYMENTS.CREDIT_CARD
                          ? "法人クレジットカード"
                          : "口座振替（法人口座もしくは代表者様口座）"}
                      </span>
                    </dd>
                  </dl>
                </div>
                {!!selectedPlan && (
                  <div className="formbox__item formbox__price">
                    <dl>
                      <dt>
                        <label>各種料金</label>
                      </dt>
                      <dd>
                        <table>
                          <thead>
                            <tr>
                              <th colSpan="2">{selectedPlan.name}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th>入会金</th>
                              <td>
                                ¥
                                {Number(
                                  paymentInfo.admission_fee
                                ).toLocaleString()}
                              </td>
                            </tr>
                            <tr>
                              <th>事務手数料</th>
                              <td>
                                ¥
                                {Number(
                                  paymentInfo.administrative_fee
                                ).toLocaleString()}
                              </td>
                            </tr>
                            {!selectedPlan.immediatesettlementadaptationflag_c && !selectedPlan.annualcontractplan_c && (
                              <>
                                {paymentInfo.monthly_fees.map((monthlyFee, i) => (
                                  <tr key={i} className="monthly_fee_row">
                                    <th>{i+1}ヶ月目 月会費</th>
                                    <td>
                                      ¥
                                      <span className="monthly_fee">
                                        {Number(monthlyFee.amount).toLocaleString()}
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              </>
                            )}
                            {selectedPlan.annualcontractplan_c && (
                              <tr>
                                <th>{calculateMembershipPeriod(paymentInfo.join_date)}</th>
                                <td>
                                  ¥
                                  {Number(
                                    paymentInfo.first_invoice + paymentInfo.proratedmonthlyfee
                                  ).toLocaleString()}
                                </td>
                              </tr>
                            )}
                            <tr>
                              <th>
                                {input.paymentMethod === PAYMENTS.CREDIT_CARD
                                  ? "初回決済日"
                                  : "初回引き落とし日"}
                              </th>
                              <td>
                                {moment(
                                  paymentInfo.first_withdrawal_date
                                ).format(JAPANESE_DATE_FORMAT)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="formbox__note">
                          ＊すべて税込み価格です。
                          <br/>
                          ＊月会費にオプションの料金も含まれています。
                        </div>
                      </dd>
                    </dl>
                  </div>
                )}
                {input.paymentMethod === PAYMENTS.CREDIT_CARD ? (
                  <>
                    <div className="formbox__item">
                      {!!input.errorMsg && (
                        <p
                          style={{
                            textAlign: "center",
                            margin: "0 30px",
                            padding: "30px 0 25px",
                          }}
                        >
                          <span
                            className="aform-error"
                            style={{ display: "block" }}
                          >
                            {input.errorMsg}
                          </span>
                          <span
                            id="fsjs_error"
                            className="aform-error"
                            style={{ display: "block" }}
                          ></span>
                        </p>
                      )}
                      <dl>
                        <dt>
                          <label>
                            クレジットカード番号
                            <span className="aform-required">必須</span>
                          </label>
                        </dt>
                        <dd>
                          <input
                            {...register("cardNo", VALIDATION_RULES.cardNo)}
                            type="text"
                            className={errors.cardNo ? "parsley-error" : ""}
                          ></input>
                          <div id="cardnumber-error" className="aform-error">
                            {errors.cardNo && (
                              <ul className="parsley-errors-list filled">
                                <li className="parsley-required">
                                  {errors.cardNo.message}
                                </li>
                              </ul>
                            )}
                          </div>
                          <div className="formbox__note">
                            （半角/ハイフンなし）
                          </div>
                        </dd>
                      </dl>
                    </div>
                    <div className="formbox__item">
                      <dl>
                        <dt>
                          <label>
                            有効期限（月/年）
                            <span className="aform-required">必須</span>
                          </label>
                        </dt>
                        <dd>
                          <select
                            {...register("cardExpirationMonth")}
                            className={
                              errors.cardExpirationMonth
                                ? "expires parsley-error"
                                : "expires"
                            }
                          >
                            {[
                              "",
                              "01",
                              "02",
                              "03",
                              "04",
                              "05",
                              "06",
                              "07",
                              "08",
                              "09",
                              "10",
                              "11",
                              "12",
                            ].map((value) => (
                              <option value={value} key={value}>
                                {value}
                              </option>
                            ))}
                          </select>
                          <label>/</label>
                          <select
                            {...register("cardExpirationYear")}
                            className={
                              errors.cardExpirationYear
                                ? "expires parsley-error"
                                : "expires"
                            }
                          >
                            {getExpirationYearList().map((value) => (
                              <option value={value} key={value}>
                                {value}
                              </option>
                            ))}
                          </select>
                          <div id="expires-error" className="aform-error">
                            {errors.cardExpirationYear && (
                              <ul className="parsley-errors-list filled">
                                <li className="parsley-required">
                                  {errors.cardExpirationYear.message}
                                </li>
                              </ul>
                            )}
                          </div>
                        </dd>
                      </dl>
                    </div>
                    <div className="formbox__item formbox__item--number">
                      <dl>
                        <dt>
                          <label>
                            セキュリティコード
                            <span className="aform-required">必須</span>
                          </label>
                        </dt>
                        <dd>
                          <div className="aform-input-example parts-10">
                            クレジットカードの裏面または表面に記載された、3桁または4桁の番号を入力してください。
                          </div>
                          <input
                            {...register("cvv", VALIDATION_RULES.cvv)}
                            type="text"
                            className={errors.cvv ? "parsley-error" : ""}
                          ></input>
                          <div id="code-error" className="aform-error">
                            {errors.cvv && (
                              <ul className="parsley-errors-list filled">
                                <li className="parsley-required">
                                  {errors.cvv.message}
                                </li>
                              </ul>
                            )}
                          </div>
                        </dd>
                      </dl>
                    </div>
                    <div className="formbox__item">
                      <dl>
                        <dt>
                          <label>
                            クレジットカード名義
                            <span className="aform-required">必須</span>
                          </label>
                        </dt>
                        <dd>
                          <input
                            {...register("holderName", VALIDATION_RULES.holderName)}
                            type="text"
                            placeholder="TARO YAMADA"
                            className={errors.holderName ? "parsley-error" : ""}
                          ></input>
                          <div id="holder-name-error" className="aform-error">
                            {errors.holderName && (
                              <ul className="parsley-errors-list filled">
                                <li className="parsley-required">
                                  {errors.holderName.message}
                                </li>
                              </ul>
                            )}
                          </div>
                        </dd>
                      </dl>
                    </div>
                  </>
                ) : (
                  <div className="formbox__item">
                    {!!input.errorMsg && (
                      <p
                        style={{
                          textAlign: "center",
                          margin: "0 30px",
                          padding: "30px 0 25px",
                        }}
                      >
                        <span
                          className="aform-error"
                          style={{ display: "block" }}
                        >
                          {input.errorMsg}
                        </span>
                        <span
                          id="fsjs_error"
                          className="aform-error"
                          style={{ display: "block" }}
                        ></span>
                      </p>
                    )}
                    <dl>
                      <dt>
                        <label>
                          口座名義人名フリガナ
                          <span className="aform-required">必須</span>
                        </label>
                      </dt>
                      <dd>
                        {/* form.text_field :smbc_bill_kana, id: 'furigana', value: @individual_member.smbc_bill_kana, required: true, "data-parsley-zenkana": "", "data-parsley-errors-container": "#account-error", "data-parsley-required-message": "口座名義人名フリガナを入力してください", "data-parsley-zenkana-message": "口座名義人名フリガナは、全角で入力してください", "data-parsley-trigger": "focusout change input" */}
                        <input
                          {...register(
                            "smbcBillKana",
                            VALIDATION_RULES.smbcBillKana
                          )}
                          type="text"
                          className={errors.smbcBillKana ? "parsley-error" : ""}
                        ></input>
                        <div id="account-error" className="aform-error">
                          {errors.smbcBillKana && (
                            <ul className="parsley-errors-list filled">
                              <li className="parsley-required">
                                {errors.smbcBillKana.message}
                              </li>
                            </ul>
                          )}
                        </div>
                        <div className="formbox__note">（全角カナ）</div>
                      </dd>
                    </dl>
                  </div>
                )}
              </div>
              {/* /formbox */}

              {/* buttons */}
              {input.paymentMethod === PAYMENTS.BANK_TRANSFER && (
                <p className="bank_note_text">
                  これより先は銀行サイトへ遷移します。<br/>
                  銀行サイトで登録を完了させるには、最後に必ず<br/>
                  「ログアウト（収納企業サイトへ戻る）」ホタンを押下してください。<br/>
                  押下しない場合、口座振省の設定が完了しない場合があります。<br/>
                </p>
              )}
              <div className="buttons buttons--confirm js-indicate js-indicate-btin">
                <Link to={ROUTES.corpProfile}>
                  <button
                    type="button"
                    className="button button--large button--blackline button--back js-back"
                  >
                    <span className="button__label">戻る</span>
                  </button>
                </Link>
                <button
                  type="submit"
                  className="button button--large button--arrow js-submit"
                  disabled={!isValid}
                >
                  <span className="button__label">申し込む</span>
                </button>
              </div>
              {/* /buttons */}
            </form>
          </div>
        </div>
      </div>
      {/* /content */}
      {isEmailCheckModal && (
        <div className="email-modal-layer" onClick={emailCheckClose}>
          <div className="modal-layer-inner">
            <div className="modal-content">
              <p className="modal-content-text">
                ご入力いただいたメールアドレスはすでに登録されています。
                <br/>
                入会手続きの完了メールが届いているかご確認いただき、届いていない場合はお問い合わせください。
              </p>
            </div>
            <svg class="modal-close-icon" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
              <path d="M12.3975 0.26232C12.5783 0.0944597 12.8173 0.00321534 13.064 0.00776998C13.3108 0.0123246 13.5462 0.112323 13.7207 0.286742C13.8952 0.461161 13.9953 0.696413 13.9998 0.943036C14.0044 1.18966 13.9131 1.42844 13.7451 1.60918L8.35352 6.99764L13.7451 12.3861C13.9131 12.5668 14.0044 12.8056 13.9998 13.0522C13.9953 13.2989 13.8952 13.5341 13.7207 13.7085C13.5462 13.883 13.3108 13.983 13.064 13.9875C12.8173 13.9921 12.5783 13.9008 12.3975 13.733L7.00587 8.34551L1.61325 13.735L1.5952 13.752C1.41234 13.9182 1.17205 14.007 0.924984 13.9996C0.677917 13.9922 0.443381 13.8892 0.270824 13.7123C0.0982674 13.5354 0.00117266 13.2985 1.05527e-05 13.0515C-0.00115156 12.8044 0.0937095 12.5666 0.264595 12.3881L5.65621 6.99965L0.265597 1.61019C0.0940411 1.4301 -0.000300417 1.19015 0.00270483 0.941514C0.00571008 0.692884 0.105824 0.455272 0.281683 0.279386C0.457542 0.103499 0.695218 0.00326687 0.943992 7.84523e-05C1.19277 -0.00310996 1.43294 0.0909978 1.61325 0.26232L7.00487 5.65078L12.3975 0.26232Z" fill="white"/>
            </svg>
          </div>
        </div>
      )}

    </main>
    // /main
  );
};

export default InputCorpPayment;
