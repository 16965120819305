import axios from "axios";
import React, { useRef, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { inputState } from "../states/atoms/inputAtom";
import { PATTERN, ROUTES, STORAGE_KEY } from "../Constants";
import { getSessionStorageItem } from "../utils/StorageUtil";
import { useCsrf } from "../Repositories";

/**
 * 会員情報の入力画面
 *
 * @returns
 */
const InputProfile = () => {
  // 定数宣言
  // Recoil State
  const [input, setInput] = useRecoilState(inputState);
  const [isIframeModal, setIframeModal] = useState(false);
  const [iframeModalSrc, setIframeModalSrc] = useState('');
  const [query, setQuery] = useState();
  const [isEmailCheckModal, setEmailCheckModal] = useState(false);

  const { token } = useCsrf();

  const navigate = useNavigate();

  const shopName = getSessionStorageItem(STORAGE_KEY.SHOP_NAME);
  const queryAccountId = getSessionStorageItem('queryAccountId');

  // 本人確認書類種別
  const ID_VERIFICATION_DOC_TYPES = {
    driversLicense: 1,
    healthInsuranceCard: 2,
    passport: 3,
    basicResidentRegisterCard: 4,
    certificateOfAlienRegistration: 5,
    myNumberCard: 6,
  };

  // 本人確認書類1 DOM
  const INPUT_ID_DOC_1_DOM = useRef();

  // 本人確認書類2 DOM
  const INPUT_ID_DOC_2_DOM = useRef();

  // Form
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    mode: "all",
    defaultValues: {
      lastName: input.lastName,
      firstName: input.firstName,
      lastNameKana: input.lastNameKana,
      firstNameKana: input.firstNameKana,
      birthYear: input.birthYear,
      birthMonth: input.birthMonth,
      birthDay: input.birthDay,
      underage: input.underage,
      gender: input.gender,
      phoneNumber: input.phoneNumber,
      email: input.email,
      emailConfirm: input.emailConfirm,
      password: input.password,
      zipcode: input.zipcode,
      prefecture: input.prefecture,
      city: input.city,
      street: input.street,
      idVerificationDocType: input.idVerificationDocType,
      idDoc1: input.idDoc1,
      idDoc2: input.idDoc2,
      triggerToJoin: input.triggerToJoin,
      purposeMembership: input.purposeMembership,
      reasonsChoosingWorldPlus: input.reasonsChoosingWorldPlus,
    },
  });

  useEffect(() => {
    underAgeCheck();
    const fetchData = () => {
      axios
        .get(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${query}`)
        .then((res) => {
          if (res.status !== 200) {
            throw new Error("住所検索に失敗しました。");
          } else {
            // 郵便番号の桁数が不正の場合のメッセージ
            if (res.data.message) {
              return;
            }

            // 郵便番号が存在しない場合のエラーメッセージ
            if (res.data.results == null) {
              return;
            }

            // 取得した住所をフォームにセット
            const result = res.data.results[0];
            setValue("prefecture", result.address1);
            setValue("city", result.address2);
            setValue("street", result.address3);
            setQuery('');
          }
        })
        .catch((_) => {
          return;
        });
    };

    if (query) fetchData();
  }, [query, setValue, underAgeCheck]);

  /**
   * サブミット時処理
   *
   * @param {*} data
   */
  const onSubmit = async (data) => {
    const submitElement = document.querySelector('[type="submit"]');
    submitElement?.setAttribute('disabled', 'true');
    const loadingElement = document.querySelector('.loading2');
    loadingElement?.classList.add("show");
    const headers = {
      "X-CSRFToken": token.token,
    };
    const formData = new FormData();
    formData.append("email", data.email);
    formData.append("brand_type", "ワールドプラスジム");
    const result = await fetch("/api/sf_email_check", {
      method: "POST",
      headers: headers,
      body: formData,
    });
    const resultData = await result.json();
    if (resultData.status === 'NG') {
      submitElement?.removeAttribute('disabled');
      loadingElement?.classList.remove("show");
      window.scrollTo(0, 0);
      setEmailCheckModal(true);
      return;
    }
    const updateInput = {
      lastName: data.lastName,
      firstName: data.firstName,
      lastNameKana: data.lastNameKana,
      firstNameKana: data.firstNameKana,
      birthYear: data.birthYear,
      birthMonth: data.birthMonth,
      birthDay: data.birthDay,
      underage: data.underage,
      gender: data.gender,
      phoneNumber: data.phoneNumber,
      email: data.email,
      emailConfirm: data.emailConfirm,
      password: data.password,
      zipcode: data.zipcode,
      prefecture: data.prefecture,
      city: data.city,
      street: data.street,
      idVerificationDocType: data.idVerificationDocType,
      idDoc1: data.idDoc1,
      idDoc2: data.idDoc2,
      agree: data.agree,
      triggerToJoin: data.triggerToJoin,
      purposeMembership: data.purposeMembership,
      reasonsChoosingWorldPlus: data.reasonsChoosingWorldPlus,
    };
    setInput((currentInput) => ({
      ...currentInput,
      ...updateInput,
    }));
    submitElement?.removeAttribute('disabled');
    loadingElement?.classList.remove("show");
    navigate(ROUTES.payment);
  };

  // Formバリデーションルール
  const VALIDATION_RULES = {
    lastName: {
      required: "お名前（姓）を入力してください",
      maxLength: {
        value: 80,
        message: "お名前（姓）は80文字以内で入力してください",
      },
    },
    firstName: {
      required: "お名前（名）を入力してください",
      maxLength: {
        value: 40,
        message: "お名前（名）は40文字以内で入力してください",
      },
    },
    lastNameKana: {
      required: "お名前カナ(セイ)を入力してください",
      maxLength: {
        value: 80,
        message: "お名前カナ(セイ)は80文字以内で入力してください",
      },
      pattern: {
        value: PATTERN.ZENKAKU_KANA,
        message: "お名前カナ(セイ)は、全角カナで入力してください",
      },
    },
    firstNameKana: {
      required: "お名前カナ(メイ)を入力してください",
      maxLength: {
        value: 40,
        message: "お名前カナ(メイ)は40文字以内で入力してください",
      },
      pattern: {
        value: PATTERN.ZENKAKU_KANA,
        message: "お名前カナ(メイ)は、全角カナで入力してください",
      },
    },
    birthYear: {
      required: "生年月日(年)を選択してください",
    },
    birthMonth: {
      required: "生年月日(月)を選択してください",
    },
    birthDay: {
      required: "生年月日(日)を選択してください",
      validate: {
        invalid: (value) => {
          if (!!getValues("birthYear") && !!getValues("birthMonth")) {
            // 入力された日付の取得
            const inputYear = getValues("birthYear");
            const inputMonth = getValues("birthMonth");
            const date = new Date(inputYear + "/" + inputMonth + "/" + value);
            if (
              Number(inputYear) !== date.getFullYear() ||
              Number(inputMonth) !== date.getMonth() + 1 ||
              Number(value) !== date.getDate()
            ) {
              return "正しい日付を選択してください";
            }
          }
        },
      },
    },
    underage: {
      validate: {
        invalid: (value) => {
          if (isUnderAgeCheck()) {
            return !value ? '未成年の方は、保護者の同意が必要です。': true;
          }
          return true;
        },
      },
    },
    gender: {
      required: "性別を選択してください",
    },
    phoneNumber: {
      required: "電話番号を入力してください",
      pattern: {
        value: PATTERN.PHONE_NUMBER,
        message:
          "電話番号は、半角数字・ハイフンなし、0から始まる10-11桁で入力してください",
      },
    },
    email: {
      required: "メールアドレスを入力してください",
      pattern: {
        value: PATTERN.EMAIL,
        message: "メールアドレスを正しく入力してください",
      },
      validate: {
        invalid: async (value) => {
          const headers = {
            "X-CSRFToken": token?.token,
          };
          const formData = new FormData();
          formData.append("email", value);
          formData.append("brand_type", "ワールドプラスジム");
          const result = await fetch("/api/email_check", {
            method: "POST",
            headers: headers,
            body: formData,
          });
          const resultData = await result.json();
          if (resultData.status === 'NG') {
            return "このメールアドレスは既に登録されてます。";
          }
        },
      },
    },
    emailConfirm: {
      required: "メールアドレス(確認用)を入力してください",
      validate: {
        invalid: (value) => {
          if (getValues("email")) {
            const inputEmail = getValues("email");
            if (inputEmail !== value) {
              return "メールアドレスが一致していません。";
            }
          }
        },
      }
    },
    password: {
      required: "パスワードを入力してください",
      pattern: {
        value: PATTERN.PASSWORD,
        message:
          "大文字英字を少なくとも1文字含み、8桁以上16桁以下の半角英数字で入力してください",
      },
    },
    zipcode: {
      required: "郵便番号を入力してください",
      pattern: {
        value: PATTERN.ZIPCODE,
        message:
          "郵便番号は、半角数字・ハイフンなし、7桁の数値で入力してください",
      },
    },
    prefecture: {
      required: "都道府県を選択してください",
    },
    city: {
      required: "市区町村を入力してください",
    },
    street: {
      required: "町名・番地以降を入力してください",
    },
    idVerificationDocType: {
      required: "本人確認書類の種類を選択してください",
    },
    idDoc1: {
      required: "画像を選択してください",
    },
    idDoc2: {
      validate: {
        message: (value) =>
          (() => {
            const idVerificationDocType = Number(
              getValues("idVerificationDocType")
            );
            return (
              value === "" &&
              (idVerificationDocType ===
                ID_VERIFICATION_DOC_TYPES.driversLicense ||
                idVerificationDocType ===
                  ID_VERIFICATION_DOC_TYPES.healthInsuranceCard)
            );
          })()
            ? "画像を選択してください"
            : null,
      },
    },
    triggerToJoin: {
      required: "ワールドプラスジムをどこで知りましたか？を選択してください",
    },
    purposeMembership: {
      required: "入会の目的として最も近いものをお選びくださいを選択してください",
    },
    reasonsChoosingWorldPlus: {
      required: "ワールドプラスを選んだ理由として最も近いものをお選びくださいを選択してください",
    },
    agree: {
      required: "注意事項をご確認の上、同意するにチェックを付けてください",
    },
  };

  // 本人確認書類のフォーム登録
  const { name: idDoc1 } = register("idDoc1", VALIDATION_RULES.idDoc1);
  const { name: idDoc2 } = register("idDoc2", VALIDATION_RULES.idDoc2);

  /**
   * 本人確認書類を選択した際に、選択値によってフォームの表示・非表示を切り替える
   *
   * @param {*} event HTMLDomイベント
   */
  function toggleIDDocDomsDisplay(event) {
    // 既に入力された画像ファイルを削除
    setValue(idDoc1, "");
    setValue(idDoc2, "");
    INPUT_ID_DOC_1_DOM.current.value = "";
    INPUT_ID_DOC_2_DOM.current.value = "";
    setInput((currentInput) => ({
      ...currentInput,
      ...{
        idDoc1: null,
        idDoc2: null,
      },
    }));
    // バリデーションチェック発火のため、blur実行
    event.target.blur();
  }

  /**
   * アップロード画像のサムネイルを設定
   *
   * @param {*} event HTMLDomイベント
   */
  function setThumbnail(event) {
    if (event.target.files && event.target.files[0]) {
      const { files } = event.target;
      const target = event.target.name;
      setValue(target, { src: URL.createObjectURL(files[0]), file: files[0] });
      // バリデーションチェック実行
      trigger(target);
    }
  }

  /**
   *
   *
   * @param {*} type ID_VERIFICATION_DOC_TYPES
   * @returns
   */
  function isRequiredTwoImages(type) {
    if (
      Number(type) === ID_VERIFICATION_DOC_TYPES.driversLicense ||
      Number(type) === ID_VERIFICATION_DOC_TYPES.healthInsuranceCard
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * 生年月日（年）の選択肢を生成する
   *
   * @returns
   */
  function getBirthYearOptions() {
    const today = new Date();
    let firstYear = 1930; // 生年月日（年）選択肢の最初の選択肢
    let lastYear = today.getFullYear() - 13; // 生年月日（年）選択肢の最初の選択肢（現在日付の13年前まで）
    const yearOptions = [];

    for (let i = 0; i <= lastYear - firstYear; i++) {
      if (firstYear + i === 1970) {
        yearOptions.push("");
      }
      yearOptions.push(firstYear + i);
    }
    return yearOptions;
  }

  function onClickZipcode() {
    const zipcode = getValues("zipcode");

    if (!PATTERN.ZIPCODE.test(zipcode)) {
      return;
    }

    setQuery(zipcode);
  }

  function isUnderAgeCheck() {
    const date = new Date(
      getValues("birthYear"),
      getValues("birthMonth"),
      getValues("birthDay"));

    const today = new Date();

    let age = today.getFullYear() - date.getFullYear();
    const monthDiff = today.getMonth() - date.getMonth();
    const dayDiff = today.getDate() - date.getDate();

    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }

    return age >= 13 && age < 18;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function underAgeCheck() {
    if (document.getElementById("underage_form")) {
      if (isUnderAgeCheck()) {
        document.getElementById("underage_form").style.display = 'block';
      } else {
        document.getElementById("underage_form").style.display = 'none';
      }
    }
  }

  function handlerPasswordShow(event) {
    const password = document.querySelector('.input-password');
    if (password.type === "password") {
      event.target.classList.add("on");
      password.type = "text";
    } else {
      event.target.classList.remove("on");
      password.type = "password";
    }
  }

  const emailCheckClose = (event) => {
    if (event.target.className === 'email-modal-layer') {
      setEmailCheckModal(false);
    }
  }

  const iframeModal = (event) => {
    event.preventDefault();
    setIframeModalSrc(event.target.href);
    setIframeModal(true);
  }

  const iframeModalClose = (event) => {
    if (event.target.className === 'iframe-modal-layer') {
      setIframeModal(false);
    }
  }

  return (
    <>
    <main className="main">
      <div className="cover cover--contact cover--bg">
        <div className="cover__outer">
          <div className="cover__inner"></div>
        </div>
      </div>
      <div className="location">
        <p className="location__text">
          <a href={process.env.REACT_APP_WEB_MAIN_URL}>TOP</a>
          <a href={process.env.REACT_APP_WEB_MAIN_URL + "/admission/"}>
            ワールドプラスジム　{!!shopName && shopName + "　"}
            入会のお申し込み
          </a>
        </p>
      </div>
      <div className="content">
        <div className="content__outer">
          <div className="content__inner">
            <h1 className="content__title js-indicate js-indicate-btin">
              ワールドプラスジム　{!!shopName && shopName + "　"}
              入会のお申し込み
            </h1>
            <div className="step js-indicate js-indicate-btin">
              <div className="step__lists">
                <div className="step__list">
                  <span>
                    プラン<i className="pc">等の選択</i>
                  </span>
                </div>
                <div className="step__list step__list--current">
                  <span>
                    会員<i className="pc">情報の入力</i>
                  </span>
                </div>
                <div className="step__list">
                  <span>
                    決済<i className="pc">情報の入力</i>
                  </span>
                </div>
                <div className="step__list">
                  <span>
                    登録<i className="pc">完了</i>
                  </span>
                </div>
              </div>
            </div>
            <p className="content__text js-indicate js-indicate-btin">
              必要事項をご記入のうえ、「会員情報の入力へ」ボタンを押してください。
              <br />
              入力内容を訂正する場合は、「戻る」ボタンを押してください。
              <span className="need">必須</span>は必須項目です。
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="content__h2 content__h2--line js-indicate js-indicate-btin"></div>
              <div className="formbox js-indicate js-indicate-btin">
                <div className="formbox__item">
                  {/* <% if @individual_member.errors.any? %> */}
                  <p
                    style={{
                      textAlign: "center",
                      margin: "0 30px",
                      padding: "30px 0 25px",
                    }}
                  >
                    {/* <% @individual_member.errors.full_messages.each do |full_message| %> */}
                    {/* <span className="aform-error" style="display: block;"><%= full_message %></span> */}
                    {/* <% end %> */}
                  </p>
                  {/* <% end %> */}
                  <dl>
                    <dt>
                      <label>
                        お名前<span className="aform-required">必須</span>
                      </label>
                    </dt>
                    <dd>
                      <ul className="aform-name-ul">
                        <li>
                          <label>姓</label>
                          <input
                            {...register("lastName", VALIDATION_RULES.lastName)}
                            type="text"
                            className={errors.lastName ? "parsley-error" : ""}
                          ></input>
                        </li>
                        <li>
                          <label>名</label>
                          <input
                            {...register(
                              "firstName",
                              VALIDATION_RULES.firstName
                            )}
                            type="text"
                            className={errors.firstName ? "parsley-error" : ""}
                          ></input>
                        </li>
                      </ul>
                      <div id="name-error" className="aform-error">
                        {(errors.lastName || errors.firstName) && (
                          <ul className="parsley-errors-list filled">
                            {errors.lastName && (
                              <li className="parsley-required">
                                {errors.lastName.message}
                              </li>
                            )}
                            {errors.firstName && (
                              <li className="parsley-required">
                                {errors.firstName.message}
                              </li>
                            )}
                          </ul>
                        )}
                      </div>
                    </dd>
                  </dl>
                </div>
                <div className="formbox__item">
                  <dl>
                    <dt>
                      <label>
                        お名前カナ<span className="aform-required">必須</span>
                      </label>
                    </dt>
                    <dd>
                      <ul className="aform-name-ul">
                        <li>
                          <label>セイ　</label>
                          <input
                            {...register(
                              "lastNameKana",
                              VALIDATION_RULES.lastNameKana
                            )}
                            type="text"
                            className={
                              errors.lastNameKana
                                ? "js-required parsley-error"
                                : "js-required"
                            }
                          ></input>
                        </li>
                        <li>
                          <label>メイ　</label>
                          <input
                            {...register(
                              "firstNameKana",
                              VALIDATION_RULES.firstNameKana
                            )}
                            type="text"
                            className={
                              errors.firstNameKana
                                ? "js-required parsley-error"
                                : "js-required"
                            }
                          ></input>
                        </li>
                      </ul>
                      <div id="name_kana-error" className="aform-error">
                        {(errors.lastNameKana || errors.firstNameKana) && (
                          <ul className="parsley-errors-list filled">
                            {errors.lastNameKana && (
                              <li className="parsley-required">
                                {errors.lastNameKana.message}
                              </li>
                            )}
                            {errors.firstNameKana && (
                              <li className="parsley-required">
                                {errors.firstNameKana.message}
                              </li>
                            )}
                          </ul>
                        )}
                      </div>
                    </dd>
                  </dl>
                </div>
                <div className="formbox__item formbox__date">
                  <dl>
                    <dt>
                      <label>
                        生年月日
                        <span className="aform-required">必須</span>
                      </label>
                    </dt>
                    <dd>
                      <select
                        {...register("birthYear", {
                          ...VALIDATION_RULES.birthYear,
                          onChange: () => {
                            if (
                              !!getValues("birthMonth") &&
                              !!getValues("birthDay")
                            ) {
                              trigger("birthDay");
                              underAgeCheck();
                            }
                          },
                        })}
                        className={
                          errors.birthYear ||
                          errors.birthDay?.type === "invalid"
                            ? "birthday parsley-error"
                            : "birthday"
                        }
                      >
                        {getBirthYearOptions().map((value) => (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        ))}
                      </select>
                      <label>年</label>
                      <br className="sp" />
                      <select
                        {...register("birthMonth", {
                          ...VALIDATION_RULES.birthMonth,
                          onChange: () => {
                            if (
                              !!getValues("birthYear") &&
                              !!getValues("birthDay")
                            ) {
                              trigger("birthDay");
                              underAgeCheck();
                            }
                          },
                        })}
                        className={
                          errors.birthMonth ||
                          errors.birthDay?.type === "invalid"
                            ? "birthday parsley-error"
                            : "birthday"
                        }
                      >
                        {[...Array(13)].map((_, i) => (
                          <option key={i} value={i === 0 ? "" : i}>
                            {i === 0 ? "" : i}
                          </option>
                        ))}
                      </select>
                      <label>月</label>
                      <select
                        {...register("birthDay", {
                          ...VALIDATION_RULES.birthDay,
                          onChange: () => {
                            if (
                              !!getValues("birthYear") &&
                              !!getValues("birthMonth")
                            ) {
                              underAgeCheck();
                            }
                          }
                        })}
                        className={
                          errors.birthDay
                            ? "birthday parsley-error"
                            : "birthday"
                        }
                      >
                        {[...Array(32)].map((_, i) => (
                          <option key={i} value={i === 0 ? "" : i}>
                            {i === 0 ? "" : i}
                          </option>
                        ))}
                      </select>
                      <label>日</label>
                      <div id="birthday-error" className="aform-error">
                        {(errors.birthYear ||
                          errors.birthMonth ||
                          errors.birthDay) && (
                          <ul className="parsley-errors-list filled">
                            {errors.birthYear && (
                              <li className="parsley-required">
                                {errors.birthYear.message}
                              </li>
                            )}
                            {errors.birthMonth && (
                              <li className="parsley-required">
                                {errors.birthMonth.message}
                              </li>
                            )}
                            {errors.birthDay && (
                              <li className="parsley-required">
                                {errors.birthDay.message}
                              </li>
                            )}
                          </ul>
                        )}
                      </div>
                      <div id="underage_form" className="formbox__underage formbox--agree">
                        <p className="formbox__text">
                          <input
                            {...register("underage", VALIDATION_RULES.underage)}
                            id="underage"
                            type="checkbox"
                          />
                          <label htmlFor="underage"></label>
                          保護者の同意を得ている
                        </p>
                        <div id="underage-error" className="aform-error">
                          <ul className="parsley-errors-list filled">
                            {errors.underage && (
                              <li className="parsley-required">
                                {errors.underage.message}
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </dd>
                  </dl>
                </div>
                <div className="formbox__item">
                  <dl>
                    <dt>
                      <span>
                        性別
                        <span className="aform-required">必須</span>
                      </span>
                    </dt>
                    <dd>
                      <ul className="aform-radio-ul">
                        <li>
                          <input
                            {...register("gender", VALIDATION_RULES.gender)}
                            id="gender01"
                            type="radio"
                            value="male"
                          ></input>
                          <label htmlFor="gender01">男性</label>
                        </li>
                        <li>
                          <input
                            {...register("gender", VALIDATION_RULES.gender)}
                            id="gender02"
                            type="radio"
                            value="female"
                          ></input>
                          <label htmlFor="gender02">女性</label>
                        </li>
                      </ul>
                      <div id="gender-error" className="aform-error">
                        {errors.gender && (
                          <ul className="parsley-errors-list filled">
                            {errors.gender && (
                              <li className="parsley-required">
                                {errors.gender.message}
                              </li>
                            )}
                          </ul>
                        )}
                      </div>
                    </dd>
                  </dl>
                </div>
                <div className="formbox__item">
                  <dl>
                    <dt>
                      <label>
                        電話番号<span className="aform-required">必須</span>
                      </label>
                    </dt>
                    <dd>
                      <input
                        {...register(
                          "phoneNumber",
                          VALIDATION_RULES.phoneNumber
                        )}
                        type="text"
                        className={errors.phoneNumber ? "parsley-error" : ""}
                      ></input>
                      <div className="formbox__note">（半角/ハイフンなし）</div>
                      <div id="phoneNumber-error" className="aform-error">
                        {errors.phoneNumber && (
                          <ul className="parsley-errors-list filled">
                            {errors.phoneNumber && (
                              <li className="parsley-required">
                                {errors.phoneNumber.message}
                              </li>
                            )}
                          </ul>
                        )}
                      </div>
                    </dd>
                  </dl>
                </div>
                <div className="formbox__item">
                  <dl>
                    <dt>
                      <label>
                        メールアドレス
                        <span className="aform-required">必須</span>
                      </label>
                    </dt>
                    <dd>
                      <input
                        {...register("email", VALIDATION_RULES.email)}
                        type="text"
                        className={errors.email ? "parsley-error" : ""}
                      ></input>
                      <div className="formbox__note">
                        （半角）
                        <br />
                        ＊携帯電話やスマートフォンアドレスをご記入される場合や、迷惑メールの対策などでドメイン指定を行っている場合はメールが受信できない場合がございます。ドメイン指定受信・メール指定受信にて｢@worldplus-gym.com｣からメールを受信できるよう設定してください。
                        <br />
                        ＊施設入退館サービス(hacomono) にもご入力いただいたメールアドレスとパスワードで自動的にアカウント登録が行われます。
                      </div>
                      <div id="email-error" className="aform-error">
                        {errors.email && (
                          <ul className="parsley-errors-list filled">
                            {errors.email && (
                              <li className="parsley-required">
                                {errors.email.message}
                              </li>
                            )}
                          </ul>
                        )}
                      </div>
                    </dd>
                  </dl>
                </div>
                <div className="formbox__item">
                  <dl>
                    <dt>
                      <label>
                        メールアドレス(確認用)
                        <span className="aform-required">必須</span>
                      </label>
                    </dt>
                    <dd>
                      <input
                        {...register("emailConfirm", VALIDATION_RULES.emailConfirm)}
                        type="text"
                        className={errors.emailConfirm ? "parsley-error" : ""}
                      ></input>
                      <div id="emailConfirm-error" className="aform-error">
                        {errors.emailConfirm && (
                          <ul className="parsley-errors-list filled">
                            {errors.emailConfirm && (
                              <li className="parsley-required">
                                {errors.emailConfirm.message}
                              </li>
                            )}
                          </ul>
                        )}
                      </div>
                    </dd>
                  </dl>
                </div>
                <div className="formbox__item">
                  <dl>
                    <dt>
                      <label>
                        マイページログイン用パスワード<span className="aform-required">必須</span>
                      </label>
                    </dt>
                    <dd>
                      <div className="input-password-block">
                        <input
                          {...register("password", VALIDATION_RULES.password)}
                          type="password"
                          className={errors.password ? "input-password parsley-error" : "input-password"}
                        ></input>
                        <button onClick={handlerPasswordShow} type="button" className="password-show"></button>
                      </div>
                      <div className="formbox__note">
                        （大文字英字を1文字以上含む半角英数字8-16文字）
                        <br />
                        ＊会員登録後、マイページへのログインにて利用します。
                        <br />
                        ＊施設入退館サービス(hacomono) にもご入力いただいたメールアドレスとパスワードで自動的にアカウント登録が行われます。
                      </div>
                      <div id="password-error" className="aform-error">
                        {errors.password && (
                          <ul className="parsley-errors-list filled">
                            {errors.password && (
                              <li className="parsley-required">
                                {errors.password.message}
                              </li>
                            )}
                          </ul>
                        )}
                      </div>
                    </dd>
                  </dl>
                </div>
                <div
                  className="formbox__item formbox__item--zip"
                  id="yubinbango"
                >
                  <dl>
                    <dt>
                      <label>
                        郵便番号<span className="aform-required">必須</span>
                      </label>
                    </dt>
                    <dd>
                      <ul className="aform-zip-ul">
                        <li>
                          <input
                            {...register("zipcode", VALIDATION_RULES.zipcode)}
                            type="text"
                            className={
                              errors.zipcode
                                ? "p-postal-code parsley-error"
                                : "p-postal-code"
                            }
                          ></input>
                          <div className="formbox__note">
                            （半角/ハイフンなし）
                          </div>
                        </li>
                        <li>
                          <a
                            href="#yubinbango"
                            className="button js-yubinbango"
                            onClick={onClickZipcode}
                          >
                            <span className="button__label">住所を入力</span>
                          </a>
                        </li>
                      </ul>
                      <div id="zip-error" className="aform-error">
                        {errors.zipcode && (
                          <ul className="parsley-errors-list filled">
                            {errors.zipcode && (
                              <li className="parsley-required">
                                {errors.zipcode.message}
                              </li>
                            )}
                          </ul>
                        )}
                      </div>
                    </dd>
                  </dl>
                </div>
                <div className="formbox__item">
                  <dl>
                    <dt>
                      <label>
                        都道府県
                        <span className="aform-required">必須</span>
                      </label>
                    </dt>
                    <dd>
                      <select
                        {...register("prefecture", VALIDATION_RULES.prefecture)}
                        className={
                          errors.city ? "p-region parsley-error" : "p-region"
                        }
                      >
                        <option value="">選択してください</option>
                        <option value="北海道">北海道</option>
                        <option value="青森県">青森県</option>
                        <option value="岩手県">岩手県</option>
                        <option value="宮城県">宮城県</option>
                        <option value="秋田県">秋田県</option>
                        <option value="山形県">山形県</option>
                        <option value="福島県">福島県</option>
                        <option value="茨城県">茨城県</option>
                        <option value="栃木県">栃木県</option>
                        <option value="群馬県">群馬県</option>
                        <option value="埼玉県">埼玉県</option>
                        <option value="千葉県">千葉県</option>
                        <option value="東京都">東京都</option>
                        <option value="神奈川県">神奈川県</option>
                        <option value="新潟県">新潟県</option>
                        <option value="富山県">富山県</option>
                        <option value="石川県">石川県</option>
                        <option value="福井県">福井県</option>
                        <option value="山梨県">山梨県</option>
                        <option value="長野県">長野県</option>
                        <option value="岐阜県">岐阜県</option>
                        <option value="静岡県">静岡県</option>
                        <option value="愛知県">愛知県</option>
                        <option value="三重県">三重県</option>
                        <option value="滋賀県">滋賀県</option>
                        <option value="京都府">京都府</option>
                        <option value="大阪府">大阪府</option>
                        <option value="兵庫県">兵庫県</option>
                        <option value="奈良県">奈良県</option>
                        <option value="和歌山県">和歌山県</option>
                        <option value="鳥取県">鳥取県</option>
                        <option value="島根県">島根県</option>
                        <option value="岡山県">岡山県</option>
                        <option value="広島県">広島県</option>
                        <option value="山口県">山口県</option>
                        <option value="徳島県">徳島県</option>
                        <option value="香川県">香川県</option>
                        <option value="愛媛県">愛媛県</option>
                        <option value="高知県">高知県</option>
                        <option value="福岡県">福岡県</option>
                        <option value="佐賀県">佐賀県</option>
                        <option value="長崎県">長崎県</option>
                        <option value="熊本県">熊本県</option>
                        <option value="大分県">大分県</option>
                        <option value="宮崎県">宮崎県</option>
                        <option value="鹿児島県">鹿児島県</option>
                        <option value="沖縄県">沖縄県</option>
                      </select>
                      <div id="prefecture-error" className="aform-error">
                        {errors.prefecture && (
                          <ul className="parsley-errors-list filled">
                            {errors.prefecture && (
                              <li className="parsley-required">
                                {errors.prefecture.message}
                              </li>
                            )}
                          </ul>
                        )}
                      </div>
                    </dd>
                  </dl>
                </div>
                <div className="formbox__item formbox__item--address">
                  <dl>
                    <dt>
                      <label>
                        市区町村
                        <span className="aform-required">必須</span>
                      </label>
                    </dt>
                    <dd>
                      <input
                        {...register("city", VALIDATION_RULES.city)}
                        type="text"
                        className={
                          errors.city
                            ? "p-locality parsley-error"
                            : "p-locality"
                        }
                      ></input>

                      <div id="city-error" className="aform-error">
                        {errors.city && (
                          <ul className="parsley-errors-list filled">
                            {errors.city && (
                              <li className="parsley-required">
                                {errors.city.message}
                              </li>
                            )}
                          </ul>
                        )}
                      </div>
                    </dd>
                  </dl>
                </div>
                <div className="formbox__item formbox__item--address">
                  <dl>
                    <dt>
                      <label>
                        町名・番地以降
                        <span className="aform-required">必須</span>
                      </label>
                    </dt>
                    <dd>
                      <input
                        {...register("street", VALIDATION_RULES.street)}
                        type="text"
                        className={
                          errors.street
                            ? "p-street-address parsley-error"
                            : "p-street-address"
                        }
                      ></input>
                      <div id="street-error" className="aform-error">
                        {errors.street && (
                          <ul className="parsley-errors-list filled">
                            {errors.street && (
                              <li className="parsley-required">
                                {errors.street.message}
                              </li>
                            )}
                          </ul>
                        )}
                      </div>
                    </dd>
                  </dl>
                </div>
                <div className="formbox__item formbox__item--id-verification-doc">
                  <dl>
                    <dt>
                      <label>
                        本人確認書類<span className="aform-required">必須</span>
                      </label>
                    </dt>
                    <dd>
                      <select
                        {...register(
                          "idVerificationDocType",
                          VALIDATION_RULES.idVerificationDocType
                        )}
                        className={
                          errors.idVerificationDocType
                            ? "id-type-select parsley-error"
                            : "id-type-select"
                        }
                        onChange={toggleIDDocDomsDisplay}
                      >
                        <option value="">選択してください</option>
                        <option
                          value={ID_VERIFICATION_DOC_TYPES.driversLicense}
                        >
                          運転免許証
                        </option>
                        <option
                          value={ID_VERIFICATION_DOC_TYPES.healthInsuranceCard}
                        >
                          保険証
                        </option>
                        <option value={ID_VERIFICATION_DOC_TYPES.passport}>
                          パスポート
                        </option>
                        <option
                          value={
                            ID_VERIFICATION_DOC_TYPES.basicResidentRegisterCard
                          }
                        >
                          住民基本台帳カード
                        </option>
                        <option
                          value={
                            ID_VERIFICATION_DOC_TYPES.certificateOfAlienRegistration
                          }
                        >
                          外国人登録証明書
                        </option>
                        <option value={ID_VERIFICATION_DOC_TYPES.myNumberCard}>
                          マイナンバーカード
                        </option>
                      </select>
                      <div
                        id="id-verification-doc-type-error"
                        className="aform-error"
                      >
                        {errors.idVerificationDocType && (
                          <ul className="parsley-errors-list filled">
                            {errors.idVerificationDocType && (
                              <li className="parsley-required">
                                {errors.idVerificationDocType.message}
                              </li>
                            )}
                          </ul>
                        )}
                      </div>
                      <div
                        style={{
                          display: !!getValues("idVerificationDocType")
                            ? "block"
                            : "none",
                        }}
                      >
                        <label
                          htmlFor={idDoc1}
                          className={
                            errors.idDoc1 ? "button parsley-error" : "button"
                          }
                        >
                          <span className="button__label">
                            {isRequiredTwoImages(
                              getValues("idVerificationDocType")
                            )
                              ? "表面をアップロード"
                              : "画像をアップロード"}
                          </span>
                        </label>
                        {watch(idDoc1)?.src && (
                          <img
                            className="id-doc-img"
                            src={watch(idDoc1).src}
                            alt=""
                          />
                        )}
                        <div id="id-doc-1-error" className="aform-error">
                          {errors.idDoc1 && (
                            <ul className="parsley-errors-list filled">
                              <li className="parsley-required">
                                {errors.idDoc1.message}
                              </li>
                            </ul>
                          )}
                        </div>
                        <input
                          id={idDoc1}
                          name={idDoc1}
                          ref={INPUT_ID_DOC_1_DOM}
                          type="file"
                          accept="image/png, image/jpeg"
                          style={{ display: "none" }}
                          onChange={setThumbnail}
                        ></input>
                      </div>
                      <div
                        style={{
                          display: isRequiredTwoImages(
                            getValues("idVerificationDocType")
                          )
                            ? "block"
                            : "none",
                        }}
                      >
                        <label
                          htmlFor={idDoc2}
                          className={
                            errors.idDoc2 ? "button parsley-error" : "button"
                          }
                        >
                          <span className="button__label">
                            裏面をアップロード
                          </span>
                        </label>
                        {watch(idDoc2)?.src && (
                          <img
                            className="id-doc-img"
                            src={watch(idDoc2).src}
                            alt=""
                          />
                        )}
                        <div id="id-doc-2-error" className="aform-error">
                          {errors.idDoc2 && (
                            <ul className="parsley-errors-list filled">
                              <li className="parsley-required">
                                {errors.idDoc2?.message}
                              </li>
                            </ul>
                          )}
                        </div>
                        <input
                          id={idDoc2}
                          name={idDoc2}
                          ref={INPUT_ID_DOC_2_DOM}
                          type="file"
                          accept="image/png, image/jpeg"
                          style={{ display: "none" }}
                          onChange={setThumbnail}
                        ></input>
                      </div>
                      <div className="formbox__note">
                        以下のいずれかをアップロードください
                        <br />
                        ・運転免許証（表面/裏面）
                        <br />
                        ・保険証（表面/裏面）
                        <br />
                        ・パスポート
                        <br />
                        ・住民基本台帳カード
                        <br />
                        ・外国人登録証明書
                        <br />
                        ・マイナンバーカード
                        <br />
                        （「氏名」「住所」「生年月日」のご本人確認ができる証明書）
                        <br />
                        アップロード形式：.jpg, .png（1枚につき上限5MB）
                        <br />
                        なお、未成年の方がご入会の場合には、保護者の方がお申込みとなります。その際は、保護者の方のご本人確認証書が必要となります。
                      </div>
                    </dd>
                  </dl>
                </div>
                <div className="formbox__item">
                  <dl>
                    <dt>
                      <label>
                        ワールドプラスジムをどこで知りましたか？
                        <span className="aform-required">必須</span>
                      </label>
                    </dt>
                    <dd>
                      <select {...register("triggerToJoin", VALIDATION_RULES.triggerToJoin)}>
                        <option value="">選択してください</option>
                        <option value="店頭や看板をみて">店頭や看板をみて</option>
                        <option value="手配りのチラシ">手配りのチラシ</option>
                        <option value="折込みチラシ">折込みチラシ</option>
                        <option value="インターネット検索">インターネット検索</option>
                        <option value="SNS等">SNS等</option>
                        <option value="紹介">紹介</option>
                        <option value="WEB上の広告">WEB上の広告</option>
                        <option value="テレビCM">テレビCM</option>
                        <option value="その他">その他</option>
                      </select>
                    </dd>
                  </dl>
                </div>
                <div className="formbox__item">
                  <dl>
                    <dt>
                      <label>
                        入会の目的として最も近いものをお選びください
                        <span className="aform-required">必須</span>
                      </label>
                    </dt>
                    <dd>
                      <select {...register("purposeMembership", VALIDATION_RULES.purposeMembership)}>
                        <option value="">選択してください</option>
                        <option value="ダイエット、シェイプアップ">ダイエット、シェイプアップ</option>
                        <option value="運動不足解消、健康維持">運動不足解消、健康維持</option>
                        <option value="筋トレ">筋トレ</option>
                        <option value="ストレス解消">ストレス解消</option>
                        <option value="人とのコミュニケーション">人とのコミュニケーション</option>
                        <option value="特になし">特になし</option>
                        <option value="その他">その他</option>
                      </select>
                    </dd>
                  </dl>
                </div>
                <div className="formbox__item">
                  <dl>
                    <dt>
                      <label>
                        ワールドプラスを選んだ理由として最も近いものをお選びください
                        <span className="aform-required">必須</span>
                      </label>
                    </dt>
                    <dd>
                      <select {...register("reasonsChoosingWorldPlus", VALIDATION_RULES.reasonsChoosingWorldPlus)}>
                        <option value="">選択してください</option>
                        <option value="価格">価格</option>
                        <option value="家から通いやすい">家から通いやすい</option>
                        <option value="マシン設備の充実">マシン設備の充実</option>
                        <option value="その他設備の充実">その他設備の充実</option>
                        <option value="営業時間">営業時間</option>
                        <option value="家族、知人が通っていたから">家族、知人が通っていたから</option>
                        <option value="全国チェーンの安心感">全国チェーンの安心感</option>
                        <option value="その他">その他</option>
                      </select>
                    </dd>
                  </dl>
                </div>
              </div>
              <div className="message js-indicate js-indicate-btin">
                <div className="message__box">
                  <p className="message__title">注意事項</p>
                  <ul className="message__cautions">
                    <li className="message__caution">
                      ・本クラブ施設利用時はトレーニングシューズ（内履き）に履き替え、トレーニングウエアを着用する。
                      <br />
                    </li>
                    <li className="message__caution">
                      ・ロッカー利用時に鍵を紛失してシリンダー交換に至った場合は3000円(税込み)を支払います。
                      <br />
                    </li>
                    <li className="message__caution">
                      ・故意に器具の破損、備え付けの備品やタオル等を持ち帰った場合は賠償いたします。
                      <br />
                    </li>
                    <li className="message__caution">
                      ・【ワールドプラスジム】　退会する場合は退会月の1カ月前の15日までに登録店舗で、申請します。
                      <br />
                    </li>
                    <li className="message__caution">
                      （例：１月１０日退会申請の場合、2月末日退会です。）
                      <br />
                    </li>
                    <li className="message__caution">
                      ・【ワールドプラスライト】　退会する場合は退会月の1カ月前の月末までに登録店舗で、申請します。
                      <br />
                    </li>
                    <li className="message__caution">
                      （例：１月２５日退会申請の場合、2月末日退会です。）
                      <br />
                    </li>
                    <li className="message__caution">
                      ・6,570円プランでの入会の場合、月会費発生月から6ヶ月間在籍いたします。
                      <br />
                    </li>
                    <li className="message__caution">
                      ・6,570円プランでの入会で、万が一6ヶ月を満たさず退会する場合は残りの期間の会費を一括にて支払います。
                      <br />
                    </li>
                    <li className="message__caution">
                      ・会員でない者をスタッフの許可なく故意に施設に入店させた場合、施設が定める賠償額を支払います。
                      <br />
                    </li>
                    <li className="message__caution">
                      ・刺青、タトゥーが現在入っている、今後入れることがあっても、長袖やサポーター等を着用するなどをして隠します。
                      <br />
                    </li>
                    <li className="message__caution">
                      ・施設利用にあたり、スタッフがいない時間でのマシンの利用に付随するすべてのリスクを負う事を承諾します。
                    </li>
                  </ul>
                </div>
              </div>
              <div className="formbox formbox--agree js-indicate js-indicate-btin">
                <div className="formbox__item">
                  <div className="formbox__column">
                    <span className="aform-required">必須</span>
                  </div>
                  <div className="formbox__column">
                    <p className="formbox__text">
                      <input
                        {...register("agree", VALIDATION_RULES.agree)}
                        id="agree"
                        type="checkbox"
                      />
                      <label htmlFor="agree"></label>
                      <a
                        href="/terms"
                        target="_blank"
                        className="link link--blank"
                        rel="noreferrer"
                        onClick={iframeModal}
                      >
                        会員会則
                      </a>
                      、
                      {/* <% if @individual_member.shop.name == 'ワールド+ジム 岸田堂店' || @individual_member.shop.name == 'ワールド+ジム 西野店' %> */}
                      <a
                        href="https://www.worldplus-gym.com/terms/docs/terms-light.pdf"
                        className="link link--blank"
                        target="_blank"
                        rel="noreferrer"
                      >
                        ワールドプラスライト店舗用 会員会則
                      </a>
                      、{/* <% end %> */}
                      <a
                        href="/privacy"
                        className="link link--blank"
                        target="_blank"
                        rel="noreferrer"
                        onClick={iframeModal}
                      >
                        個人情報保護方針
                      </a>
                      、<br className="sp" />
                      上記注意事項に同意する
                    </p>
                    <div id="agree-error" className="aform-error">
                      {errors.agree && (
                        <ul className="parsley-errors-list filled">
                          {errors.agree && (
                            <li className="parsley-required">
                              {errors.agree.message}
                            </li>
                          )}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="buttons buttons--confirm js-indicate js-indicate-btin">
                <Link to={queryAccountId ? `${ROUTES.plan}?account_id=${queryAccountId}`: ROUTES.plan}>
                  <button
                    type="button"
                    className="button button--large button--blackline button--back js-back"
                  >
                    <span className="button__label">戻る</span>
                  </button>
                </Link>
                <button
                  type="submit"
                  className="button button--large button--arrow js-submit"
                  disabled={!isValid}
                >
                  <span className="button__label">決済情報の入力へ</span>
                </button>
              </div>
            </form>
            {/* <% end %> */}
          </div>
        </div>
      </div>
    </main>
    {isEmailCheckModal && (
      <div className="email-modal-layer" onClick={emailCheckClose}>
        <div className="modal-layer-inner">
          <div className="modal-content">
            <p className="modal-content-text">
              ご入力いただいたメールアドレスはすでに登録されています。
              <br/>
              入会手続きの完了メールが届いているかご確認いただき、届いていない場合はお問い合わせください。
            </p>
          </div>
          <svg class="modal-close-icon" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path d="M12.3975 0.26232C12.5783 0.0944597 12.8173 0.00321534 13.064 0.00776998C13.3108 0.0123246 13.5462 0.112323 13.7207 0.286742C13.8952 0.461161 13.9953 0.696413 13.9998 0.943036C14.0044 1.18966 13.9131 1.42844 13.7451 1.60918L8.35352 6.99764L13.7451 12.3861C13.9131 12.5668 14.0044 12.8056 13.9998 13.0522C13.9953 13.2989 13.8952 13.5341 13.7207 13.7085C13.5462 13.883 13.3108 13.983 13.064 13.9875C12.8173 13.9921 12.5783 13.9008 12.3975 13.733L7.00587 8.34551L1.61325 13.735L1.5952 13.752C1.41234 13.9182 1.17205 14.007 0.924984 13.9996C0.677917 13.9922 0.443381 13.8892 0.270824 13.7123C0.0982674 13.5354 0.00117266 13.2985 1.05527e-05 13.0515C-0.00115156 12.8044 0.0937095 12.5666 0.264595 12.3881L5.65621 6.99965L0.265597 1.61019C0.0940411 1.4301 -0.000300417 1.19015 0.00270483 0.941514C0.00571008 0.692884 0.105824 0.455272 0.281683 0.279386C0.457542 0.103499 0.695218 0.00326687 0.943992 7.84523e-05C1.19277 -0.00310996 1.43294 0.0909978 1.61325 0.26232L7.00487 5.65078L12.3975 0.26232Z" fill="white"/>
          </svg>
        </div>
      </div>
    )}
    {isIframeModal && (
      <div className="iframe-modal-layer" onClick={iframeModalClose}>
        <div className="iframe-modal-layer-inner">
          <iframe src={iframeModalSrc} title="iframe" frameborder="0"></iframe>
          <svg className="modal-close-icon" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path d="M12.3975 0.26232C12.5783 0.0944597 12.8173 0.00321534 13.064 0.00776998C13.3108 0.0123246 13.5462 0.112323 13.7207 0.286742C13.8952 0.461161 13.9953 0.696413 13.9998 0.943036C14.0044 1.18966 13.9131 1.42844 13.7451 1.60918L8.35352 6.99764L13.7451 12.3861C13.9131 12.5668 14.0044 12.8056 13.9998 13.0522C13.9953 13.2989 13.8952 13.5341 13.7207 13.7085C13.5462 13.883 13.3108 13.983 13.064 13.9875C12.8173 13.9921 12.5783 13.9008 12.3975 13.733L7.00587 8.34551L1.61325 13.735L1.5952 13.752C1.41234 13.9182 1.17205 14.007 0.924984 13.9996C0.677917 13.9922 0.443381 13.8892 0.270824 13.7123C0.0982674 13.5354 0.00117266 13.2985 1.05527e-05 13.0515C-0.00115156 12.8044 0.0937095 12.5666 0.264595 12.3881L5.65621 6.99965L0.265597 1.61019C0.0940411 1.4301 -0.000300417 1.19015 0.00270483 0.941514C0.00571008 0.692884 0.105824 0.455272 0.281683 0.279386C0.457542 0.103499 0.695218 0.00326687 0.943992 7.84523e-05C1.19277 -0.00310996 1.43294 0.0909978 1.61325 0.26232L7.00487 5.65078L12.3975 0.26232Z" fill="white"/>
          </svg>
        </div>
      </div>
    )}
    </>
  )
};

export default InputProfile;
